import React from 'react';
import AuctionSideComp from '../auctions/AuctionSideComp';

const AuctionSide = ( {auctionRepository}) => {

  const auctionData = auctionRepository.selectAuctions();

  return (

    <div className="col-xl-3 mb-6">
      <aside className="">
        <div className="single-widget widget_categories vod_list_wrap mb-3">
          <span className="subtitle txt_important">MSUC ART FEST 2022</span>
          <h3>다른 경매</h3>
          <div className="inner slider slider-activation-banner-3 slick-pagination-50 slick-pagination-50-2 slick-arrow-top2 pt-5">
            <ul>
            {Object.keys(auctionData).map(key => (
              <AuctionSideComp key={key} data={auctionData[key]} />
            ))}
            </ul>
          </div>
        </div>
      </aside>
    </div>
  );
};

export default AuctionSide;