import React from 'react';
import { Link } from "react-router-dom";

const MenuMobileComp = ({menuData}) => {

  const openMenu = () => {
    const popMobileMenu = document.querySelector(".popup-mobile-menu");
    popMobileMenu.classList.add("active");
  };

  const closeMenu = () => {
    const popMobileMenu = document.querySelector(".popup-mobile-menu");
    popMobileMenu.classList.remove("active");
  };

  const closeModalMenu = (e) => {
    const popMobileMenu = document.querySelector(".popup-mobile-menu");
    e.target === popMobileMenu && popMobileMenu.classList.remove("active");
  }

  return (
    <li>
      <Link to={`/auction/${menuData.info.auction_id}`} onClick={() => {closeMenu()}}>{menuData.info.artwork_name}</Link>
    </li>
  );
};

export default MenuMobileComp;