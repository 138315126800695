import React from 'react';

const ArtistDescription = ({description}) => {

  return (
    <div id="artist_description">
    </div>
  );
};

export default ArtistDescription;