import React from 'react';
import Auction from '../auctions/Auction';

const AuctionEventSet = ( { auctionData }) => {

  function getRandomProperty(obj) {
    const keys = Object.keys(obj);
    return keys[Math.floor(Math.random() * keys.length)];
  }

  return (
      <div className="container">
        <div className="row pt-8">
        {Object.keys(auctionData).map(key => (
          <Auction key={key} data={auctionData[key]} />
        ))}
        </div>
      </div>
  );
};

export default AuctionEventSet;
