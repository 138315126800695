const goods = {
  "gd0001": {
    goods_id: "gd0001",
    goods_name: "굿즈01",
    goods_price: 20000,
    goods_info: {
      thumbnail: "https://www.artnomics.co.kr/data/artworks/8753/thumb-image_500.jpg",
      images: [
        "https://www.artnomics.co.kr/data/artworks/8753/thumb-image_500.jpg",
        "https://www.artnomics.co.kr/data/artworks/8751/thumb-image_500.jpg",
      ]
    },
    goods_url: "https://www.gri-gi.com/goods/view?no=42",
    likeit: {}
  },
  "gd0002": {
    goods_id: "gd0002",
    goods_name: "굿즈02",
    goods_price: 40000,
    goods_info: {
      thumbnail: "https://www.artnomics.co.kr/data/artworks/8753/thumb-image_500.jpg",
      images: [
        "https://www.artnomics.co.kr/data/artworks/8753/thumb-image_500.jpg",
        "https://www.artnomics.co.kr/data/artworks/8751/thumb-image_500.jpg",
      ]
    },
    goods_url: "https://www.gri-gi.com/goods/view?no=42",
    likeit: {}
  },
  "gd0003": {
    goods_id: "gd0003",
    goods_name: "굿즈03",
    goods_price: 50000,
    goods_info: {
      thumbnail: "https://www.artnomics.co.kr/data/artworks/8753/thumb-image_500.jpg",
      images: [
        "https://www.artnomics.co.kr/data/artworks/8753/thumb-image_500.jpg",
        "https://www.artnomics.co.kr/data/artworks/8751/thumb-image_500.jpg",
      ]
    },
    goods_url: "https://www.gri-gi.com/goods/view?no=42",
    likeit: {}
  },
  "gd0004": {
    goods_id: "gd0004",
    goods_name: "굿즈04",
    goods_price: 50000,
    goods_info: {
      thumbnail: "https://www.artnomics.co.kr/data/artworks/8753/thumb-image_500.jpg",
      images: [
        "https://www.artnomics.co.kr/data/artworks/8753/thumb-image_500.jpg",
        "https://www.artnomics.co.kr/data/artworks/8751/thumb-image_500.jpg",
      ]
    },
    goods_url: "https://www.gri-gi.com/goods/view?no=42",
    likeit: {}
  },
  "gd0005": {
    goods_id: "gd0005",
    goods_name: "굿즈05",
    goods_price: 50000,
    goods_info: {
      thumbnail: "https://www.artnomics.co.kr/data/artworks/8753/thumb-image_500.jpg",
      images: [
        "https://www.artnomics.co.kr/data/artworks/8753/thumb-image_500.jpg",
        "https://www.artnomics.co.kr/data/artworks/8751/thumb-image_500.jpg",
      ]
    },
    goods_url: "https://www.gri-gi.com/goods/view?no=42",
    likeit: {}
  },
  "gd0006": {
    goods_id: "gd0006",
    goods_name: "굿즈06",
    goods_price: 50000,
    goods_info: {
      thumbnail: "https://www.artnomics.co.kr/data/artworks/8753/thumb-image_500.jpg",
      images: [
        "https://www.artnomics.co.kr/data/artworks/8753/thumb-image_500.jpg",
        "https://www.artnomics.co.kr/data/artworks/8751/thumb-image_500.jpg",
      ]
    },
    goods_url: "https://www.gri-gi.com/goods/view?no=42",
    likeit: {}
  },
  "gd0007": {
    goods_id: "gd0007",
    goods_name: "굿즈07",
    goods_price: 50000,
    goods_info: {
      thumbnail: "https://www.artnomics.co.kr/data/artworks/8753/thumb-image_500.jpg",
      images: [
        "https://www.artnomics.co.kr/data/artworks/8753/thumb-image_500.jpg",
        "https://www.artnomics.co.kr/data/artworks/8751/thumb-image_500.jpg",
      ]
    },
    goods_url: "https://www.gri-gi.com/goods/view?no=42",
    likeit: {}
  },
};

export default goods;