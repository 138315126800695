import React from 'react';

const VodListPage = (props) => (
<>
<section className="inner-page-banner bg-2 bg-image">
  <div className="container">
    <div className="inner text-center">
      <h1 className="title">Blog</h1>
      <nav className="mt-4">
        <ol className="breadcrumb justify-content-center">
          <li className="breadcrumb-item"><a href="#">Home</a></li>
          <li className="breadcrumb-item"><a href="#">Community</a></li>
          <li className="breadcrumb-item active" aria-current="page">Blog</li>
        </ol>
      </nav>
    </div>
  </div>
</section>

<section className="ptb-120">
  <div className="container">
    <div className="row">
      <div className="col-xl-4 col-lg-6 mb-6">
        <div className="single-blog">
          <a href="blog-details.html" className="thumb">
            <img src="./images/blog/1.jpg" alt="nft blog"/>
          </a>
          <div className="content">
            <ul className="meta">
              <li>27 April</li>
              <li><a href="#">Development</a></li>
              <li className="date"><i className="ri-time-line"></i>4 Hour Ago</li>
            </ul>
            <h4 className="title"><a href="blog-details.html">The Window Know to
                say beside you</a></h4>
          </div>
        </div>
      </div>


      <div className="col-xl-4 col-lg-6 mb-6">
        <div className="single-blog">
          <a href="blog-details.html" className="thumb">
            <img src="./images/blog/2.jpg" alt="nft blog"/>
          </a>
          <div className="content">
            <ul className="meta">
              <li>27 March</li>
              <li><a href="#">Crypto</a></li>
              <li className="date"><i className="ri-time-line"></i>7 Hour Ago</li>
            </ul>
            <h4 className="title"><a href="blog-details.html">Save Thousands Of Lives
                Through This NFT</a></h4>
          </div>
        </div>
      </div>


      <div className="col-xl-4 col-lg-6 mb-6">
        <div className="single-blog">
          <a href="blog-details.html" className="thumb">
            <img src="./images/blog/3.jpg" alt="nft blog"/>
          </a>
          <div className="content">
            <ul className="meta">
              <li>14 Feb</li>
              <li><a href="#">Digital Art</a></li>
              <li className="date"><i className="ri-time-line"></i>4 Hour Ago</li>
            </ul>
            <h4 className="title"><a href="blog-details.html">The NFT Know digital marketing for you</a></h4>
          </div>
        </div>
      </div>


      <div className="col-xl-4 col-lg-6 mb-6">
        <div className="single-blog">
          <a href="blog-details.html" className="thumb">
            <img src="./images/blog/4.jpg" alt="nft blog"/>
          </a>
          <div className="content">
            <ul className="meta">
              <li>14 February</li>
              <li><a href="#">Web NFT</a></li>
              <li className="date"><i className="ri-time-line"></i>2 Hour Ago</li>
            </ul>
            <h4 className="title"><a href="blog-details.html">How to safely purchase
                NFTs on Banu</a></h4>
          </div>
        </div>
      </div>


      <div className="col-xl-4 col-lg-6 mb-6">
        <div className="single-blog">
          <a href="blog-details.html" className="thumb">
            <img src="./images/blog/5.jpg" alt="nft blog"/>
          </a>
          <div className="content">
            <ul className="meta">
              <li>12 March</li>
              <li><a href="#">Marketing</a></li>
              <li className="date"><i className="ri-time-line"></i>8 Hour Ago</li>
            </ul>
            <h4 className="title"><a href="blog-details.html">The Lost Art Modern Best
                Photography</a></h4>
          </div>
        </div>
      </div>


      <div className="col-xl-4 col-lg-6 mb-6">
        <div className="single-blog">
          <a href="blog-details.html" className="thumb">
            <img src="./images/blog/8.jpg" alt="nft blog"/>
          </a>
          <div className="content">
            <ul className="meta">
              <li>15 July</li>
              <li><a href="#">Arts</a></li>
              <li className="date"><i className="ri-time-line"></i>3 Hour Ago</li>
            </ul>
            <h4 className="title"><a href="blog-details.html">Golden State announce
                legacy NFT collection</a></h4>
          </div>
        </div>
      </div>


      <div className="col-xl-4 col-lg-6 mb-6">
        <div className="single-blog">
          <a href="blog-details.html" className="thumb">
            <img src="./images/blog/6.jpg" alt="nft blog"/>
          </a>
          <div className="content">
            <ul className="meta">
              <li>12 September</li>
              <li><a href="#">Utility</a></li>
              <li className="date"><i className="ri-time-line"></i>9 Hour Ago</li>
            </ul>
            <h4 className="title"><a href="blog-details.html">The beginner’s guide to
                sell digital art NFTs</a></h4>
          </div>
        </div>
      </div>


      <div className="col-xl-4 col-lg-6 mb-6">
        <div className="single-blog">
          <a href="blog-details.html" className="thumb">
            <img src="./images/blog/7.jpg" alt="nft blog"/>
          </a>
          <div className="content">
            <ul className="meta">
              <li>11 August</li>
              <li><a href="#">Tranding</a></li>
              <li className="date"><i className="ri-time-line"></i>3 Hour Ago</li>
            </ul>
            <h4 className="title"><a href="blog-details.html">The window know to
                say beside you</a></h4>
          </div>
        </div>
      </div>


      <div className="col-xl-4 col-lg-6 mb-6">
        <div className="single-blog">
          <a href="blog-details.html" className="thumb">
            <img src="./images/blog/9.jpg" alt="nft blog"/>
          </a>
          <div className="content">
            <ul className="meta">
              <li>10 September</li>
              <li><a href="#">Sports</a></li>
              <li className="date"><i className="ri-time-line"></i>9 Hour Ago</li>
            </ul>
            <h4 className="title"><a href="blog-details.html">Golden State announce
                legacy NFT collection</a></h4>
          </div>
        </div>
      </div>

    </div>
    <div className="mt-3 text-center"><a href="#" className="btn btn-gradient"><span><i className="ri-loader-4-fill"></i>Load
          More</span></a></div>
  </div>
</section>
</>
  );

export default VodListPage;