import React from 'react';

const ArtworkSlider = ({ artwork }) => {
  return (
    <a key={artwork} className="thumb">
      <img src={artwork} alt="explore-item" />
    </a>
  );
};

export default ArtworkSlider;