import React, { useEffect, useState } from 'react';
import MainTopBanner from '../components/banner/MainTopBanner';
import GalleryRolling from '../components/movies/GalleryRolling';
import MovieRolling from '../components/movies/MovieRolling';
import NoticeModal from '../components/common/NoticeModal';
import AuctionList from '../components/auctions/AuctionList';

const MainPage = ({auctionRepository}) => {

  const auctionData = auctionRepository.selectAuctions();
  // const goodsData = auctionRepository.getGoods();
  // const moviesData = auctionRepository.getMovies();
  // const moviesData2 = auctionRepository.getMovies2();

  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);

  return (
    <>
      <MainTopBanner />
      <section className="ptb-120">

        <div class="container">
          <div className="section-title">
            <div className="row d-flex-between">
              <div className="col-xxl-6 col-lg-6 col-md-12 mb-12">
                <span className="subtitle">MSUC ART FEST 2022</span>
                <p>
                Mad Skull Universe Club(NFT)홀더 작가들 중심으로 현대미술과 NFT콜라보미술이 함께하는 미술 축제로
                ART Living and NFT verse 예술과 삶 그리고 NFT가 이끄는 메타버스 세계를 경험해보세요.<br/>
                작가들의 창의력이 어우러진 이색적이고 참신한 콜라보 작품을 감상할 수 있는 흥미로운 기회를 누릴 수 있습니다. 
                </p>
              </div>
              <div className="col-xxl-5 col-lg-5 col-md-12 mb-12 order-lg-2">
                <img src="./img/main/main_clip.jpg" width="100%" alt="" />
              </div>
            </div>
          </div>
        </div>

        <AuctionList auctionData = {auctionData} />

      </section>

      <NoticeModal />
    </>
  );
};

export default MainPage;
