import { getDatabase, ref, set, onValue, off} from 'firebase/database';

class ManageUserService {
  constructor(app) {
    this.db = getDatabase(app);
  }
  
  syncUsers(onUpdate) {
    const query = ref(this.db, `users`);
    onValue(query, (snapshot) => {
      const value = snapshot.val();
      value && onUpdate(value);
    });
    return () => off(query);
  }
}

export default ManageUserService;