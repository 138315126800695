import React, { useEffect } from 'react';
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.js';

const NoticeModal = () => {
  let modal;

  const fuzePopUpNotShowUNIX = parseInt(localStorage.getItem('fuzePopUpNotShow')) || 0;
  const currentUNIX = Math.floor(new Date().getTime());

  useEffect( () => {
    modal = new bootstrap.Modal(document.getElementById('notice_modal'));
    if( fuzePopUpNotShowUNIX < currentUNIX) {
      console.log("시간지남");
      modal.show();
    } else {
      console.log("시간 안 지남: ", currentUNIX);
    }
  }, []);

  const closePopup = () => {
    console.log("close popup");
    modal.hide();
  };

  const notShowForOneDay = () => {
    localStorage.setItem("fuzePopUpNotShow", new Date().getTime() + 1000 * 60 * 60 * 24);
    modal.hide();
  };

  return (
    <div className="modal fade" id="notice_modal" tabIndex="-1" aria-labelledby="placeBitLaebl" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">

        <div className="modal-content">
          <img className="w-100" src="./img/img_notice.jpg" />
          {/* <div className="p-3">
            <p className={style.modal_p}>
              <a className={style.modal_p_link} href="https://gri-gi.com/board/?id=event" target="_blank">[판화 기부 이벤트 게시판]</a>
            </p>
            <p>
            팬분들의 요청에 의해  
            전시는 5일간 연장하여 10월 25일까지 전시됩니다. <br/>
            아직 관람하지 못하신 분들께서는 
            갤러리K 남산타워점에서 작품을 관람하실 수 있습니다.  
            </p>
          </div> */}
          <h3 className='modal_popup_notice'>경매가 종료되었습니다.</h3>
          <div className="modal-footer">
            <button type="button" className="btn btn-gradient btn-small" onClick={notShowForOneDay}><span>오늘 하루 보이지 않기</span></button>
            <button type="button" className="btn btn-secondary btn-small" onClick={closePopup}><span>닫기</span></button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoticeModal;