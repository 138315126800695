import React from 'react';
import { Link } from "react-router-dom";

const MenuComp = ({menuData}) => {
  return (
    <li>
    <Link to={`/auction/${menuData.info.auction_id}`}>
      <span className="active" href="#">{menuData.info.artwork_name}</span>
    </Link>
  </li>
  );
};

export default MenuComp;