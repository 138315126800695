import React, { memo } from 'react';

const Preloader = memo(() => (
  <div id="preloader">
    <div id="ctn-preloader" className="ctn-preloader">
      <div className="animation-preloader">
        <div className="icon">
          <img src="./img/logo-preloader.png" alt="logo" className="m-auto d-block" /> 
          <span></span>
        </div>
      </div>
    </div>
  </div>
));
export default Preloader;
