import React, { useEffect, useState } from 'react';
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.js';

const BidingNoticeModal = ( { authService }) => {

  const [ phone, setPhone ] = useState('');
  const [ userInfo, setUserInfo ] = useState('');
  const [ agree, setAgree ] = useState('');

  const onChange = (event) => {
    setPhone(event.target.value);
  };

  const onAgree = ( event) => {
    setAgree(event.target.checked);
  }

  const onSubmit = () => {
    if(!agree) {
      alert("'개인정보 수집 및 이용 동의'을 확인하세요");
      return;
    }
    if(!phone) {
      alert("전화번호를 입력하세요");
      return;
    }
    authService.updateUserInfoData(userInfo, phone);
  };

  const closeModal = () => {
    const myModalEl = document.getElementById('biding_notice_modal');
    const modal = bootstrap.Modal.getInstance(myModalEl);
    modal.hide();
  }

  useEffect( () => {
    authService.onAuthChange(user => {
      setUserInfo(user);
      console.log("추가정보: ", userInfo);
    });
  });

  return (
    <div className="modal fade" id="biding_notice_modal" tabIndex="-1" aria-labelledby="placeBitLaebl" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header flex-column px-8">
            <h3 className="modal-title" id="placeBitLaebl">경매 참여를 위한 추가 정보 입력 </h3>
          </div>
          <div className="modal-body px-8 py-5">
            <span className="fw-bold mb-1">전화번호</span>
            <div className="row">
              <div className="col-8">              
                <input
                name='phone'
                type='tel'
                placeholder='전화번호'
                value={phone}
                onChange={onChange}
                className='form-input'
                required
                />
              </div>
              <div className="col-4"><button onClick={ onSubmit} className="btn btn-gradient">확인</button></div>
            </div>

            <div className="popup_agree mt-4 mb-1">
              <label htmlFor='agree_privacy' className="mr-3">[필수] 개인정보 수집 및 이용 동의</label>
              <input
                name='agree_privacy'
                id='agree_privacy'
                type='checkbox'
                onChange={onAgree}
                checked={agree}
              />
            </div>
            <a href="https://sprinkle-justice-3a1.notion.site/06a8c625c27349eeaa9cbf798941157c" target="_blank" className="d-block text-decoration-underline mb-6 fw-500">개인정보처리방침</a>
              
              <h6>유의사항</h6>
              <ul className="info_caution2 mt-1">
                <li>경매기간은 9월 20일부터 10월 20일까지 1차 경매와 2차 경매로 진행됩니다.</li>
                <li>2차 경매 시 입찰 보증금 20만원을 입금하신 고객분들만 응찰하실 수 있습니다. (무통장입금: 농협 301-0285-8113-21 (주)갤러리케이)</li>
                <li>경매는 매일 낮 12시 오픈 되어 밤 12시 종료됩니다.</li>
              </ul>
              <p className="txt_important">일일 경매 참여는 5회만 참여 가능합니다.</p>
              <div className="col-12 text-right"><button onClick={ closeModal } className="btn btn-secondary btn-small">닫기</button></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BidingNoticeModal;