import React from 'react';

const ChatElement = ({chat}) => {

  function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}

  return (
    <li className="single-comment-box d-flex-between ">
      <div className="inner d-flex-start">
        <span className="avatar">
          <img src={chat.thumbnail} alt="author" />
        </span>

        <div className="content">
          <h5 className="title"><a>{chat.name}</a><span className="date-post">{formatDate(chat.id)}</span></h5>
          <pre className="chat_message">{chat.message}</pre>
        </div>
      </div>
      {/* <div className="reply"><i className="ri-arrow-right-line"></i></div> */}
    </li>
  );
};

export default ChatElement;