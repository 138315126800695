import React, { useEffect, useState } from 'react';

const ManageAuctionPage = ({manageUserService, auctionRepository} ) => {

  // const [ users, setUsers ] = useState({});
  const [ bid1, setBid1] = useState({});
  // const [ bid2, setBid2] = useState({});
  // const [ bid3, setBid3] = useState({});
  // const [ bid4, setBid4] = useState({});
  // const [ bid5, setBid5] = useState({});
  // const [ bid6, setBid6] = useState({});
  // const [ bid7, setBid7] = useState({});

  useEffect( () => {
    // manageUserService.syncUsers(setUsers);
    auctionRepository.syncAuctionBid('ac0001', setBid1);
    // auctionRepository.syncAuctionBid('ac0002', setBid2);
    // auctionRepository.syncAuctionBid('ac0003', setBid3);
    // auctionRepository.syncAuctionBid('ac0004', setBid4);
    // auctionRepository.syncAuctionBid('ac0005', setBid5);
    // auctionRepository.syncAuctionBid('ac0006', setBid6);
    // auctionRepository.syncAuctionBid('ac0007', setBid7);
  }, []);

  useEffect( () => {

    const bid = bid1;

    console.log("bid: ", bid);

    // targetObj.forEach(key => {
    //   console.log("key: ", key);
    //   Object.keys(bid).map( key => {
    //     console.log(bid[key]);
    //   });
    // })

    
  }, [bid1]);
  
  return (
    <div>
      
    </div>
  );
};

export default ManageAuctionPage;