import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ChatElement from './ChatElement';

const ChatFeed = ( {authService, auctionRepository, auctionId}) => {

  const [chats, setChat] = useState({});
  const formRef = useRef();
  const messageRef = useRef();

  const [aucId, setAuctionId] = useState(auctionId);

  const historyState = useHistory().state;
  const [userId, setUserId] = useState(historyState && historyState.id);
  const [user, setUserInfo] = useState({});

  console.log("ChatFeed: ", auctionId);

  const onSubmit = event => {
    event.preventDefault();

    if(user.uid) {
      if(!messageRef.current.value) {
        alert("댓글을 입력하세요.")
        return;
      }
      const chats = {
        id: Date.now(), //uuid
        message: messageRef.current.value || '',
        name: user.displayName,
        thumbnail: user.photoURL || '',
      }
      formRef.current.reset();
      auctionRepository.insertAuctionChat(userId, auctionId , chats);
    } else {
      authService.openLoginModal();
    }
  };

  useEffect(() => {
    authService.onAuthChange(user => {
      if (user) {
        setUserId(user.uid);
        console.log("Login: ",user);
        setUserInfo(user);
      } else {
        console.log("Not Login");
      }
    });
  }, [auctionId]);
  
  useEffect( () => {
    const stopSync = auctionRepository.syncAuctionChat(auctionId, chats => {
      setChat(chats);
      console.log("ChatFeed chats: ", chats);
    });
    return () => stopSync();
  }, [auctionId]);

  useEffect( () => {
    setAuctionId(auctionId);
  }, [aucId]);

  
  return (

      <div className="comment-box-wrapper styler-1">
        <h3 className="mb-3 text-large text-decoration-none">댓글추가</h3>
        <div className="form-inner">
          <div className="row reply-form">
            <form ref={formRef}>
              <div className="row">
                <div className="col-10 mb-4">
                    <textarea ref={messageRef} name="message" id="message" cols="20" rows="1" placeholder="댓글을 입력해 주세요"></textarea>
                </div>
                <div className="col-2 web-btn">
                  <button className="btn btn-gradient btn-small" type="submit" onClick={onSubmit}><span>Submit</span></button>
                </div>
                <div className="col-2 mobile-btn">
                  <button className="btn btn-gradient" type="submit"><i className="ri-send-plane-fill p-0"></i></button>
                </div>
              </div>
            </form>


            <hr className="mb-6" />
          </div>
        </div>
        <h3 className="mb-4 text-large text-decoration-none">댓글 {Object.keys(chats).length}개</h3>
        <ul className="comment-box-inner">

          {Object.keys(chats).map(key => (
            <ChatElement key={key} chat={chats[key]} />
          ))}

        </ul>
      </div>
  );
};

export default ChatFeed;