// import { useHistory } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.js';

import style from './BidingRegistModal.module.css';

const BidingRegistModal = ({auctionRepository, authService, auctionId}) => {

  const formRef = useRef();
  const userName = useRef();
  const userMobile = useRef();
  const bankName = useRef();
  const bankAccount = useRef();
  const inpCertCode = useRef();

  const [userId, setUserId] = useState([]);
  const [certCode, setCertCode] = useState('');
  const [isCertCode, setIsCertCode] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  // Checkbox 
  const agreeAuctionCheck = () => setIsChecked(!isChecked)

  authService.onAuthChange(user => {
    if(user) {
      setUserId(user.uid);
    } 
  });

  const makeCertNumbuer = () => {   
    const randomNumeber = parseInt(Math.random() * 10000).toString().padStart(4, '0');
    console.log("random number: ", randomNumeber);
    return randomNumeber    
  }

  // 인증문자 발송
  const sendCertCode = ( event) => {
    event.preventDefault();

    if(!userMobile.current.value) {
      alert("휴대폰번호를 입력하세요.");
      return;
    }
    setCertCode(makeCertNumbuer());
  }

  // 인증번호 확인 [인증하기] 클릭
  const checkCertCode = ( event ) => {

    console.log("checkedCertcode: ")
    event.preventDefault();
    if(inpCertCode.current.value == certCode) {
      console.log("일치: ", inpCertCode, certCode);
      setIsCertCode(true);
      alert("휴대폰 인증을 완료하였습니다");
      
      const compCheckCert = document.querySelector('#check_cert_comp');
      compCheckCert.style.display = 'none';
      inpCertCode.current.value = '';
      const inputMobile = document.querySelector('#input_mobile');
      inputMobile.readOnly = true;

    } else {
      console.log("불일치: ", inpCertCode, certCode);
      setIsCertCode(false);
      alert("인증코드가 잘못되었습니다");
      inpCertCode.current.value = '';
    }
  }

  // 신청하기
  const onSubmit = event => {
 
    event.preventDefault();
    if(!isChecked) {
      alert("입찰보증금 동의를 체크해주세요");
      return;
    }

    if(!userName.current.value) {
      alert("이름(예금주명)을 입력하세요");
      return;
    }

    if(!isCertCode) {
      alert("휴대폰번호 인증을 하세요");
      return;
    }

    if(!bankName.current.value) {
      alert("은행명을 입력하세요");
      return;
    }
    if(!bankAccount.current.value) {
      alert("계좌번호를 입력하세요");
      return;
    }

    const form = {
      user_id: userId,
      auction_id: auctionId,
      user_name: userName.current.value || '',
      user_mobile: userMobile.current.value || '',
      bank_name: bankName.current.value || '',
      bank_account: bankAccount.current.value || '',
      remit: false,
      reg_date: Date.now(),
    }

    auctionRepository.insertBidingInfo(form);
    
    formRef.current.reset();
    
    const myModalEl = document.getElementById('biding_regist_modal');
    const modal = bootstrap.Modal.getInstance(myModalEl);
    modal.hide();
    
  };


  useEffect( () => {

    if(!certCode) {
      console.log("certCode: ''", certCode)
      return;
    }
    const hostname = process.env.REACT_APP_NODE_SERVER_HOSTNAME;
    const sendMessageUrl = `${hostname}/message/sendSMS`;
    console.log("checkCertCode: ", sendMessageUrl);

    const params = {
      mobile: userMobile.current.value,
      code: `${certCode}`,
      kind: '001'
    };

    console.log("params: ", params);
    axios.post(sendMessageUrl, params).then( (res) => {
      console.log("res: ", res);
      if(res.status == 200) {
        alert("인증문자가 발송되었습니다");
        const compCheckCert = document.querySelector('#check_cert_comp');
        compCheckCert.style.display = 'flex';
      }
    }).catch( (err) => {
      console.log("err: ", err);
    });
      
  }, [certCode]);

  return (

    <div className="modal fade" id="biding_regist_modal" tabIndex="-1" aria-labelledby="placeBitLaebl" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content modal-bank mt-10">
          <div className="modal-header flex-column px-8">
            <h3 className="modal-title" id="placeBitLaebl">입찰보증금 신청</h3>
            <button type="button" className="btn-custom-closer" data-bs-dismiss="modal" aria-label="Close"><i
                className="ri-close-fill"></i></button>
          </div>
          <div className="modal-body px-8 py-5">


            <form ref={formRef}>
              <ul>
                <li>
                  <div className={`${style.notice_biding_regist} mt-3`}>
                    당사는 노쇼 방지를 위해 실제 구입하실 의향이 있으신 분들께, 
                    입찰 보증금 제도를 실시하여 경매를 진행하고자 합니다.
                    <br/>
                    경매보증금 5만원을 아래의 계좌로 입금해주셔야 경매참여가 가능합니다.<br/>
                    미낙찰 시 경매보증금은 경매 종료 후 반환해 드립니다. 
                  </div>
                </li>
                <li className='mb-4'>
                  <p className='m-0 lh-1'>MSUC 작품 경매 진행에 동의 하십니까?</p>
                  <div className='form-signup'>
                    <input type="checkbox" name="check_notice" id="check_1" checked={isChecked} onChange={agreeAuctionCheck} /> 
                    <label className='m-0 ml-2' htmlFor="check_1">동의함</label>
                    <a href="https://metal-notebook-ba6.notion.site/2-1ff464231c94460cae99d61cd71673e3" target="_blank" className='text-decoration-underline ml-8'>경매 안내</a>
                  </div>

                </li>
                <li className="mt-3 mb-5">
                  <label>이름(입금자명)</label>
                  <input ref={userName}  type="text" name="user_name" className="form-control" />
                </li>
                <li className="mb-5">
                  <label>휴대폰</label>
                  <div className='d-flex justify-content-between'>
                    <input ref={userMobile} type="tel" name="mobile" id="input_mobile" className="form-control input-controll w-65" placeholder="휴대폰번호를 입력하세요." />
                    <button className="form-controls btn btn-secondary btn-small" onClick={sendCertCode}>인증하기</button>
                  </div>
                  <div id="check_cert_comp" className='justify-content-between mt-2' style={{display: 'none'}}>
                    <input ref={inpCertCode} type="tel" name="mobile" className="form-control input-controll w-65" autocomplete="one-time-code" placeholder="인증번호를 입력하세요" />
                    <button className="form-controls btn btn-gradient btn-small" onClick={checkCertCode}>확인</button>
                  </div>
                </li>
                <li className="mb-5">
                  <label>계좌번호(반환목적)</label>
                  <div className="d-flex justify-content-between">
                    <input ref={bankName} type="text" name="bank_name" className="form-control w-30" placeholder="은행명" />
                    <input ref={bankAccount} type="text" name="bank_account" className="form-control w-65" placeholder="계좌번호" />
                  </div>
                </li>

                <ul className="info_caution2 mt-1">
                  <li>무통장입금: 농협 301-0285-8113-21 (주)갤러리케이</li>
                  <li>입금액: 5만원</li>
                </ul>
                <li className="mt-8 text-center modal-footer justify-content-center">
                  <button className="form-btn auth-form-btn btn btn-gradient btn-small" onClick={onSubmit}>신청하기</button>
                </li>
              </ul>
            </form>
          </div>

        </div>
      </div>
    </div>

  );
};

export default BidingRegistModal;