import React, { useEffect, useRef, useState } from 'react';
import $ from 'jquery';
import { useParams } from 'react-router-dom';
import "slick-carousel";
import AuctionSet from '../components/auctions/AuctionSet';
import ChatFeed from '../components/chats/ChatFeed';
import BidingRegistModal from '../components/auctions/BidingRegistModal';
import BidingNoticeModal from '../components/auctions/BidingNoticeModal';
import AuctionSide from '../components/auctions/AuctionSide';
import ArtistDescription from '../components/auctions/ArtistDescription';

const AuctionPage = ( { authService, auctionRepository } ) => {

  const { auctionId } = useParams();
  const auctionInfo = auctionRepository.getAuction(auctionId);

  useEffect( () => {
    $(".slider-activation-banner-3").slick({
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      arrows: true,
      adaptiveHeight: false,
      prevArrow:
      '<button class="slide-arrow prev-arrow"><i class="ri-arrow-left-line"></i></button>',
      nextArrow:
      '<button class="slide-arrow next-arrow"><i class="ri-arrow-right-line"></i></button>',
      autoplay: false,
      fade: true,
      cssEase: "linear",
    });

  }, []);

  useEffect( () => {
    $("#artist_description").html(auctionInfo.info.artwork.description);
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, [auctionId]);

  return (
    <>
      <section id="top" className={`inner-page-banner bg-2 bg-image ${auctionId}`}>
        <div className="container">
          <div className="inner text-center">
            <h1 className="title">{auctionInfo.info.artist_name}</h1>
            <nav className="mt-4">
              <ol className="breadcrumb justify-content-center">
                <li className="breadcrumb-item"><a href="/">Home</a></li>
                <li className="breadcrumb-item active" aria-current="page">{auctionInfo.info.artist_name}</li>
              </ol>
            </nav>
          </div>
        </div>
      </section> 
      <section className="product-details section-bg-separation-2 pt-120 pb-90">
        <div className="container">
          <div className="row">
            <AuctionSet 
              auctionId = {auctionId}
              auctionInfo={auctionInfo} 
              auctionRepository={auctionRepository}
              authService={authService} 
            />
          </div>
        </div>
      </section>
      <section className="pt-120 pb-90 blog-details-wrapper">
        <div className="container">
          <div className="row">
          <div className="col-xl-9 col-lg-12 mb-6">
            <div className="blog-content">
              <h3 class="mt-2">경매 안내</h3>
              <ul class="mt-2 mb-3">
                <li class="mb-1">경매기간은 2022년 12월 26일부터 2023년 1월 26일까지 진행 됩니다.</li>
                <li class="mb-1">경매 참여 시 보증금 5만원을 입금하신 고객분들만 참여하실 수 있습니다.<br/>
                <b>(무통장입금: 농협 301-0285-8113-21 (주)갤러리케이)</b></li>
                <li class="mb-1">경매는 매일 낮 12시 오픈 되어 밤 12시 종료됩니다.</li>
                <li class="mb-1">일일 경매 참여는 5회만 참여 가능합니다.</li>
                <li class="mb-1">경매 수익금은 미술 및 문화예술의 발전 등에 사용될 예정입니다.</li>
                <li class="mb-1">작품의 수령 및 배송: 무료배송(갤러리K에서 운송)</li>
              </ul>
              <p class="txt_important font-size-1-2">경매가 종료되면 이메일을 통해 낙찰 결과를 안내 드립니다. <strong>낙찰자는 경매 후 총 구매대금을 입금하셔야 합니다.</strong><br/>
              낙찰 철회 시 수수료가 부과될 수 있으며 2순위 낙찰자에게 전달됩니다.</p>
              <p class="txt_important font-size-1-2">
              총 구매대금을 한국미술렌탈협회(무통장 입금 : 국민은행 851401-00-053656 한국미술렌탈협회)에 입금하셔야 합니다.  
              </p>

              <div class="warning mt-7 mb-7">
                <div class="row">
                  <div class="col-md-2 col-sm-4 d-flex justify-content-center m-auto">
                    <img class="w-80 m-auto" src="./img/artwork/warning.svg" alt="주의 아이콘"/>
                  </div>
                  <div class="col-md-10 col-sm-12">
                    <p>웹사이트에 실린 모든 텍스트, 이미지, 그래픽, 사운드 파일, 애니메이션 파일, 비디오 파일 및 그 배열은 
                    저작권 및 기타 다른 지적재산권의 보호 대상으로서, 상업적인 용도나 배포를 목적으로 복사 또는 변형하거나, 다른 웹사이트에 게시하는 것은 금지되어 있습니다. 
                    또한, 제3자에게 저작권이 있는 이미지가 포함된 경우도 있습니다.</p>
                  </div>
                </div>
              </div>
              <ArtistDescription />
            </div>
              <ChatFeed
                auctionRepository={auctionRepository} 
                authService={authService} 
                auctionId = {auctionId}
              />
            </div>
            <AuctionSide auctionRepository={auctionRepository} />
          </div>
        </div>
        </section>

        <BidingRegistModal
          auctionRepository = {auctionRepository}
          authService = {authService} 
          auctionId = {auctionId}
        />

        <BidingNoticeModal 
          authService = {authService}
        />
    </>
  );
};

export default AuctionPage;