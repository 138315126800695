import React, { useState } from 'react';
import { Link } from "react-router-dom";


const AuctionSideComp = ( { data }) => {
  const [info, setInfo] = useState(data.info);
  return (
    <li>
      <div className="explore-style-one">
        <div className="thumb main_auction_item">
        <Link to={'/auction/' + info.auction_id}><img src={info.artwork.images[0]} alt="nft live auction thumbnail" /></Link>
        </div>
      </div>
    </li>
  );
};

export default AuctionSideComp;