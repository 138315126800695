import React, { useCallback } from 'react';
import { Switch, Route, useHistory, BrowserRouter } from 'react-router-dom';
import Header from './components/common/Header';
import Preloader from './components/common/Preloader';
import MainPage from './pages/MainPage';
import AuctionPage from './pages/AuctionPage';
import Footer from './components/common/Footer';
import LoginModal from './components/common/LoginModal';
import CompanyIntroPage from './pages/CompanyIntroPage';
import VodListPage from './pages/VodListPage';
import MoviePopup from './components/movies/MoviePopup';
import ManageUserPage from './pages/ManageUserPage';
import ManageAuctionPage from './pages/ManageAuctionPage';


function App({ authService, auctionRepository, manageUserService}) {
  
  const onLogout = useCallback(
    () => {
      authService.logout();
    },
    [authService]
  );

  return (
    <BrowserRouter>
      <div className='app'>
        <Preloader />
        <div className="row">
          <div className="col-xl-2 col-lg-d-none">
          <Header onLogout={onLogout} 
            authService={authService} 
            auctionRepository={auctionRepository}
          />
          </div>
          <div className="col-xl-10 col-lg-12">
            <Switch>
                <Route exact path='/'>
                  <MainPage 
                    authService={authService} 
                    auctionRepository={auctionRepository}
                  />
                </Route>
                <Route exact path='/auction/:auctionId'>
                  <AuctionPage 
                    authService={authService} 
                    auctionRepository={auctionRepository}
                  />
                </Route>
                <Route exact path='/intro'>
                  <CompanyIntroPage/>
                </Route>
                <Route exact path='/vod'>
                  <VodListPage/>
                </Route>
                <Route exact path='/manage/user'>
                  <ManageUserPage 
                    manageUserService={manageUserService}
                    auctionRepository={auctionRepository}
                  />
                </Route>
                <Route exact path='/manage/auction'>
                  <ManageAuctionPage 
                    manageUserService={manageUserService}
                    auctionRepository={auctionRepository}
                  />
                </Route>
            </Switch>
          </div>
        </div>
        <Footer />
        <LoginModal authService={authService} />
        <MoviePopup
          auctionRepository={auctionRepository}
          
        />

      </div>
    </BrowserRouter>
  );
}

export default App;
