import React from 'react';
import { numberComma, parseDate } from '../../util/utils';

const AuctionMyHistory = ({bid}) => {
  return (
    <div className="single-item-history d-flex-center justify-content-between">
      <div className="d-flex flex-column">
        <p className="mb-0"><span className="color-primary fw-500">{numberComma(bid.amount)}</span>원 입찰</p>
        <span className="date">{parseDate(bid.bid_id)}</span>
      </div>
      <div className="d-flex flex-column price-history">
        <p className="mb-0">입찰금액</p>
        <span className="date">{numberComma(bid.amount)}원</span>
      </div>
    </div>
  );
};

export default AuctionMyHistory;