import React, { useEffect, useState } from 'react';

const ManageUserPage = ( {manageUserService, auctionRepository} ) => {

  const [ users, setUsers ] = useState({});
  const [ bid1, setBid1] = useState({});
  const [ bid2, setBid2] = useState({});
  const [ bid3, setBid3] = useState({});
  const [ bid4, setBid4] = useState({});
  const [ bid5, setBid5] = useState({});
  const [ bid6, setBid6] = useState({});
  const [ bid7, setBid7] = useState({});

  useEffect( () => {
    manageUserService.syncUsers(setUsers);
    auctionRepository.syncAuctionBid('ac0001', setBid1);
    auctionRepository.syncAuctionBid('ac0002', setBid2);
    auctionRepository.syncAuctionBid('ac0003', setBid3);
    auctionRepository.syncAuctionBid('ac0004', setBid4);
    auctionRepository.syncAuctionBid('ac0005', setBid5);
    auctionRepository.syncAuctionBid('ac0006', setBid6);
    auctionRepository.syncAuctionBid('ac0007', setBid7);
  }, []);

  useEffect( () => {
    console.log("data: ", bid1, bid2, bid3, bid4, bid5, bid6, bid7);

    const targetObj = Object.keys(users).filter(key => users[key].displayName === undefined);
    
    targetObj.forEach(key => {
      console.log("key: ", key);
      const bid = bid1.history.realtime;
      Object.keys(bid).map( key => {
        if(bid[key].user_id === key) {
          users.displayName = bid[key].user_name;
        }
      });
      
    })

    
  }, [users, bid1, bid2, bid3]);

  return (
    <div>
      <table>
        <thead>
          <th>uid</th>
          <th>이름</th>
          <th>이메일</th>
          <th>전화번호</th>
        </thead>
        <tbody>
        {
          
          Object.keys(users).map( key => 
            <tr key={key}>
              <td>{key}</td>
              <td>{users[key].uid}</td>
              <td>{users[key].displayName}</td>
              <td>{users[key].email}</td>
              <td>{users[key].phone}</td>
            </tr>
          )
        }
        </tbody>
      </table>
    </div>
  );
};

export default ManageUserPage;