import React from 'react';
import { parseDate, numberComma } from '../../util/utils';

const AuctionUserHistory = ({bid}) => {
  return (
      <div className="single-item-history d-flex-center">
      <span className="avatar">
        <img src={bid.thumbnail} alt="history" />
        <i className="ri-check-line"></i>
      </span>
      {/* //프로필 사진 */}
      <div className="content">
        <p>
          <span className="fw-500">{bid.user_name}</span>님이 
          <span className="color-primary fw-500"> {numberComma(bid.amount)} 원</span> 입찰했습니다.
        </p>
        <span className="date">{parseDate(bid.bid_id)}</span>
      </div>
    </div>
  );
};

export default AuctionUserHistory;