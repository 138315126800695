const auctionsInfo = {
  'ac0001': {
    'info': {
      auction_id: 'ac0001',
      status: "02",
      artwork_name: '로칸킴 x msuc 콜라보',
      artist_name: '로칸킴',
      auction_date: '2023-01-25',
      artwork: {
        size: '30 x 30 cm',
        images: [
          './img/artworks/01.jpg'
        ],
        description: `
          <h3 class="mt-10">작품 설명</h3>
          <div class="mb-15">
            <blockquote>
              <h4>Space oddity</h4>
              <p>당신의 인생에서 첫 영화는 어떤 것인가? 나의 인생에서 첫 영화는 7살 때 아빠를 따라 가서 보게 된 스타워즈 제국의 역습이다. 주인공이 광할한 우주를 배경으로 액션활극을 펼치는 것을 보고 
              시각적 충격을 받은 나는 하루종일 영화와 우주에 대한 공상을 하게 되었고 우주비행사가 되고 싶다는 꿈을 꾸기도 하였다. 시간이 지나 성인이 되어가며 미 디어를 통해 다양한 sf 영화와 소설, 우주 개발과 관련된 다큐멘터리를 접하게 되면서 우 주에 대한 막연한 공상과 환상들은 현실이라는 거대한 벽과 마주하게 되어 사라지게 되 었다.</p>
              <p >we choose to go to the moon in this decade and do the other things, 
              not because they ar e easy, but because they are hard</p>
              <p>우리는 1970년대가 오기 전에 달에 갈 것이며, 또 다른 탐사 임무를 찾을 것입니다. 이 일이 쉬워서가 아니라, 어려운 일이라는 점을 알기 때문입니다.</p>
              <p>달 탐사 프로젝트의 시작을 알린 존,F 케네디 대통령 1961년 5월 25일, 미국 상,하원 합동 연설은 본격적인 우주 경쟁 시대를 열었다.<br/>
                아폴로 11호의 달 착륙이 성공과 소련의 붕괴로 인해 미국은 우주 경쟁에서 승리했지만, 그 과정 속에서 수많은 우주 비행사들의 희생이 있었다. 그들의 희생으로 인해 인간은 달과 별 그리고 미지의 우주에 조금 더 가가갈 수 있게 되었다. 10 개의 space oddity 연작은 어린 시절 우주를 좋아했던</p>
              <p>그리고 우주비행사가 되고 싶었던 과거의 꿈을 실현해준 우주 비행사들에 대한 일종의 헌사이다. 그들이 우주에서 직면했을 아름답지만 무서운 우주의 양면성과 그 과정에서 겪었을 정신적 육체적인 고통과 외로움들을 대리 체험하도록 시각화한 작업이다.</p>
              <p>우주에 다가기 우주비행사들의 여정은 현재 그리고 다가올 2022 년과 그 이후에도 이어질 것이다. 국가들과의 우주 패권 경쟁은 물론 스페이스x와 블루 오리진을 비롯한 민간 기업들도 우주로의 도약을 꿈꾸며 치열하게 경쟁을 하고 있다. 그 경쟁의 과정 속에서 우주비행사들이 고통과 시련을 통해 인류는 아름다운 미지의 우주에 더 다가가게 될 것이다. ‘인생은 가까이서 보면 비극 멀리서 보면 희극’이라는 찰리 체플린의 말은 우주비행사들의 인생과 일맥상통할 지 모른다.</p>
              </>
            </blockquote>
            <blockquote>
              <p>MSUC x 콜라보 작업은 space oddity 작업 속에 들어간 가상의 상황을 가정해 만든 작품으로 우주 복을 입고 외계의 행성에서 길을 잃은 캐릭터의 외로움을 표현했다.</p>
            </blockquote>
          </div>
            
          <hr></hr>

          <h3 class="mb-5 mt-15">작가소개</h3>
          <blockquote>
            <div class="row">
              <div class="col-xxl-4 col-lg-4 col-md-12">
                <img class="img-fluid" src="./img/artist/01.jpg" alt=""/>
              </div>
              <div class="col-xxl-7 col-lg-7 col-md-12">
                <h4>로칸킴</h4>
                <h5 class="mt-5"><개인전></h5>
                <ul class="mt-3">
                  <li>2022  반포미술아카데미(예정),서울,한국</li>
                  <li>2022  <SPACE ODDITY> 관훈갤러리 , 서울, 한국</li>
                  <li>2022  <THE SPACE ODDITY> 서울신문사 서울 갤러리, 서울, 한국</li>
                </ul>
                <h5 class="mt-5"><단체전></h5>
                <ul class="mt-3">
                  <li>2022 <BWB 2022>, 벡스코, 부산, 한국</li>
                  <li>2022 <어스무드>, 갤러리어스, 서울, 한국</li>
                  <li>2022 <아이랩미디어 홍대 AK몰 상설전시>, ak몰 3층  서울, 한국 </li>
                  <li>2022 <GLITCHERS ART INVASION VOL.1> 아트스페이스 블루스크린, 서울, 한국</li>
                  <li>2021-2022 《2022 EARTH MARKET》, 라이즈호텔, 서울, 한국 </li>
                  <li>2021 《모꼬지심 지구조작단》, 부산은행 신창동,지점,부산 ,한국</li> 
                </ul>
              </div>
            </div>
          </blockquote>
          `,      
      },
      goods: [
        "gd0001", "gd0002", "gd0003"
      ]
    }
  },

  'ac0002': {
    'info': {
      auction_id: 'ac0002',
      status: "02",
      artwork_name: 'MSUC X 김석 콜라보 ',
      artist_name: '김석',
      auction_date: '2023-01-25',
      artwork: {
        size: '50 x 50 cm',
        images: [
          './img/artworks/02.jpg'
        ],
        description: `
          <h3 class="mt-10">작품 설명</h3>
          <div class="mb-15">
            <blockquote>
              <h4>김석</h4>
              <p>누구나 알고 있는 스마일 캐릭터로 스팩트럼의 한계에 도전하며 페인팅,조형,미디어, 등 다양한 작품을 선보인다.</p>
              <p>작가는 여러가지 매체를 통해 자신의 소중한 경험에서 느꼈던 좋은, 행복한 기억을 전달하는 메시지를 전달한다.</p>
              <p>우리는 1970년대가 오기 전에 달에 갈 것이며, 또 다른 탐사 임무를 찾을 것입니다. 이 일이 쉬워서가 아니라, 어려운 일이라는 점을 알기 때문입니다.</p>
              <p>MSCUC 콜라보 작품에서는 “죽느냐 사느냐" 선택은 항상 너의 몫이다 라는 메시지를 담았다.</p>
            </blockquote>
            <blockquote>
              <p>MSUC x 콜라보 작업은 space oddity 작업 속에 들어간 가상의 상황을 가정해 만든 작품으로 우주 복을 입고 외계의 행성에서 길을 잃은 캐릭터의 외로움을 표현했다.</p>
            </blockquote>
          </div>
            
          <hr></hr>

          <h3 class="mb-5 mt-15">작가소개</h3>
          <blockquote>
            <div class="row">
              <div class="col-xxl-4 col-lg-4 col-md-12">
                <img class="img-fluid" src="./img/artist/02.jpg" alt=""/>
              </div>
              <div class="col-xxl-7 col-lg-7 col-md-12">
                <h4>로칸킴</h4>
                <ul class="mt-3">
                  <li>스마일 캐릭터와 경험과 기억의 페인팅 2022</li>
                  <li>< Art in the Bay > Busan</li>
                  <li><< Mirror X > 11.23~11.27 Im-Setudio Seoul/li>
                  <li>< FFalo in Seoul NEWERA > 11.8~11.27 Space445 Gallery Seoul.</li>
                  <li>< Premier ART Seoul 2022 Exhibition > 10.13~10.16 Gallery41 The Westin Josun Seoul.</li>
                  <li>< GLOW in the DARK Exhibition > 10.18~20 Understandavenue Seoul.</li>
                  <li>< ALL ABOUT COLLECTING Exhibition > 10.6~10.11 Seoul.</li>
                  <li>< Plas Hotel Art Show Exhibition > 10.1~10.3 Gallery41 Venue _ JW MARRIOTT in SEOUL < Find Your Star Exhibition > SOVAC X FLEA:AUCTION Walkerhill Vista Seoul.</li>
                  <li>< EARTH MOOD Exhibition > Seoul.</li>
                  <li>< 2022 NET FAIR -THE HYUNDAI SEOUL Exhibition > Seoul.</li>
                  <li>< HERITAGE Exhibition > Seoul.</li>
                  <li>< UPBIT DROP > Seoul.</li>
                  <li>< Infocomm 2022 in Lasvegas Exhibition > Lasvegas.</li>
                  <li>< 2022 5nd CAAF Exhibition > Seoul.</li>
                  <li>< AK & Hongdae Exhibition > Seoul.</li>
                  <li>< Art in the Bay Exhibition > Busan.</li>
                  <li>< Urban Break, Gallery41 Exhibition > Seoul.</li>
                  <li>< Hongdae Ak Mall, I Museam Exhibition > Seoul.</li>
                  <li>< In-Joy of Art Exhibition > Seoul.</li>
                  <li>< Art Exhibition Alpha Exhibition > Seoul.</li>
                  <li>< Musinsa, Soldout Exhibition > Seoul.</li>
                  <li>< Seoul Auction Exhibition > Seoul.</li>
                  <li>< Hankook Tire Exhibition > Pan Gyo.</li>
                  <li>< 2022 Bama Exhibition > Bexco, Busan.</li>
                  <li>< Srising Star Exhibition > Seoul.</li>
                  <li>< Water & Boat Exhibition > Seoul.</li>
                  <li>< Nike Airmax Day Nike & Kasina With Stonrok > Seoul.</li>
                  <li>< Freedom > Solo Exhibition, Seoul.</li>
                  <li>< Hyundai Department Store Artist Exhibition > Seoul.</li>
                </ul>
              </div>
            </div>
          </blockquote>
          `
      }
    }
  },

  'ac0003': {
    'info': {
      auction_id: 'ac0003',
      status: "03",
      artwork_name: 'MSUC X MeME 콜라보',
      artist_name: '미미',
      auction_date: '2023-01-25',
      artwork: {
        size: '30 x 30 cm',
        images: [
          './img/artworks/03.jpg'
        ],
        description: `
          <h3 class="mt-10">작품 설명</h3>
          <div class="mb-15">
            <blockquote>
              <p>내 작업의 모든 소재는 생물학적 결핍 극복을 위해 인위적 요소들로 주어진 환경을 변형시키고 스스로 필요한 세계를 만들어 나가는 인간의 주체성을 의미한다.<br />
              이에 행복이라는 일상적 소재를 음식의 물성에서 탐구하여 채집한 기분 좋은 촉감의 시각적 전이, 그리고 레이어간의 오버랩 등의 방식으로 나만의 작품세계를 구성한다.<br />
              이어 MSUC와 콜라보 작업을 통해 행복이라는 일상적 소재로 주체적 삶을 표방하고 모두가 꿈꾸는 유토피아를 그려본다</p>
          </div>
            
          <hr></hr>

          <h3 class="mb-5 mt-15">작가소개</h3>
          <blockquote>
            <div class="row">
              <div class="col-xxl-4 col-lg-4 col-md-12">
                <img class="img-fluid" src="./img/artist/03.jpg" alt=""/>
              </div>
              <div class="col-xxl-7 col-lg-7 col-md-12">
                <h4>미미</h4>
                <h5 class="mt-5">Solo Exhibition</h5>
                <ul class="mt-3">
                  <li>2020 MMMM Solo Exhibition, The Wall Gallery</li>
                  <li>2022 Dreaming Peace with artist MeME Solo Exhibition, L7BY Lotte Hotel , Seoul</li>
                  <li>2022 Find the last Neverland Solo Exhibition, Mansion9 Gallery, Seoul</li>
                  <p>A total of 10 times</p>
                </ul>
                <h5 class="mt-5">Group exhibition</h5>
                <ul class="mt-3">
                  <li>2019 Design Art Fair Arts Center Hangaram Museum, Seoul</li>
                  <li>2019 Formative Art Fair, COEX Exhibition Hall, Seoul</li>
                  <li>2019 Art Pet 2 Korea-Japan Illustration Exchange Exhibition, Gallery Vinci, Seoul </li>
                  <li>2021 Geek Village, Space Wadiz, Seoul </li>
                  <li>2021 Recreation, Space Wadiz, Seoul </li>
                  <li>2021 Disco Disco (DIS-CO), Han River Museum, Namyangju</li>
                  <li>2021 Art on the Line, K Museum of Modern and Contemporary Art, Seoul </li>
                  <li>2021 2222EARTHMARKET, Rise Hotel, Seoul </li>
                  <li>2022 Art Festival From ITALY, Galleria Antonio, Milan, Italy </li>
                  <li>2022 ZeroBase HIP! POP! Seoul Auction Gangnam Center, Seoul  </li>
                  <li>2022  IN-JOY OF ART, Kwanhoon Gallery , Seoul </li>
                  <p>A total of 22 times</p>
                </ul>
              </div>
            </div>
          </blockquote>
          `
      }
    }
  },

  'ac0004': {
    'info': {
      auction_id: 'ac0004',
      status: "04",
      artwork_name: 'MSUC X 운주 콜라보',
      artist_name: '운주',
      auction_date: '2023-01-25',
      artwork: {
        size: '27.3 x 27.3cm',
        images: [
          './img/artworks/04.jpg'
        ],
        description: `
          <h3 class="mt-10">작품 설명</h3>
          <div class="mb-15">
            <blockquote>
              <p>RPG 게임 캐릭터 만드는 데도 반나절은 걸리는 터라 원하는 MSUC 작업을 고르는 일이 꽤 재미있었다.<br />
              #9572는 화려한 옷이나 악세사리는 없지만 독특한 네온 색감에 어딘가 꿈을 꾸는 듯한 눈, 몸에 타투, 펑키한 초커까지 딱 내가 원하는 모습이었다.</p>
              <p>작가의 그림에서 구름은 하늘을 떠돌다 도시로 내려와서 자신이 경험하고 좋아하는 것들을 기록하며 자신을 찾아가고 있다. <br />
              무한한 가능성이 열린 가상 세계를 경험하며 행복한 상상을 하고 있는 작품이다.</p>
              </>
            </blockquote>
          </div>
            
          <hr></hr>

          <h3 class="mb-5 mt-15">작가소개</h3>
          <blockquote>
            <div class="row">
              <div class="col-xxl-4 col-lg-4 col-md-12">
                <img class="img-fluid" src="./img/artist/04.jpg" alt=""/>
              </div>
              <div class="col-xxl-7 col-lg-7 col-md-12">
                <h4>운주</h4>
                <h5 class="mt-5"><학력></h5>
                <ul class="mt-3">
                  <li>2013서울과학기술대학교 디자인과 졸업</li>
                </ul>
                <h5 class="mt-5"><전시이력></h5>
                <ul class="mt-3">
                  <li>2020.10 영등포 네트워크 예술제 아티스트 전시 참여</li>
                  <li>2022.02 현대백화점 천호점 작가 초청 전시 참여  </li>
                  <li>2022.04 개인전 : Cloud (영등포구 예술도시) </li>
                  <li>2022.06 기획전 : Feast of Art (성수동 솔드아웃) </li>
                  <li>2022.06 단체전 : Alpha (압구정 립합 라운지) </li>
                  <li>2022.07 기획전 : Free your art (플리옥션)</li>
                  <li>2022.08 개인전 : 운주전 (영등포구 메이커스유니온스퀘어) </li>
                  <li>2022.11 부천부평 아트페어 (부천시 아트벙커 B39) </li>
                  <li>2022.12 기획전 : MUSC ART FEST 2022 (남산 갤러리K)</li>
                  <li>2022.12 단체전 : 연말소품전 (갤러리 고아트)</li>
                </ul>
                <h5 class="mt-5"><활동이력></h5>
                <ul class="mt-3">
                  <li>2017-2018 Superga 수페르가 커스텀 아티스트 활동 (영국)</li>
                  <li>2018-2019 Stella McCartney 스텔라맥카트니 마카쥬 아티스트 및 기술자문 (영국) </li>
                  <li>2020.05 여행잡지 <Travi> 디지털 일러스트 및 글 기고 </li>
                  <li>2022.02 나이키 골프 모자&골프화 아트워크 디자인 및 핸드페인팅 작업</li>
                  <li>2022.10 영등포문화재단 도림천 생태극장 빛갤러리 고보조명 아트워크 참여  </li>
                  <li>2020-2022 중앙중학교, 수주고등학교 등 학교 및 기업 등 커스텀 출강</li>
                  <li>2022.02 현대백화점 천호점 아트 페인팅 워크샵 진행 </li>
                  <li>2022.02 SM 창의공방 가수 NCT127 오브제 페인팅 워크샵 컨텐츠 진행</li>
                  <li>2022.03 마마무 휘인 '휘프트포유' 오브제 페인팅 컨텐츠 진행 </li>
                  <li>2022.03 카시나 성수 나이키 에어맥스데이 아티스트 참여 </li>
                </ul>
              </div>
            </div>
          </blockquote>
          `
      }
    }
  },

  'ac0005': {
    'info': {
      auction_id: 'ac0005',
      status: "05",
      artwork_name: '알레고리풍경_블레씽유 (Allegory-Landscape_Blessing-You)',
      artist_name: '배기열',
      auction_date: '2023-01-25',
      artwork: {
        size: '90 x 72cm',
        images: [
          './img/artworks/05.jpg'
        ],
        description: `
          <h3 class="mt-10">작품 설명</h3>
          <div class="mb-15">
            <blockquote>
              <h4>有德者 必有言 有言者 不必有德 仁者 必有勇 勇者 不必有仁</h4>
              <p>德(덕)이 있는 사람은 반드시 좋은 말을 남긴다. 하지만 좋은 말을 남겼다고 반드시 德(덕)이 있는 사람은 아니다. <br />
              仁(인)한 사람은 반드시 용기가 있다. 하지만 용기가 있다고 반드시 仁(인)한 사람은 아니다.란 의미로 한번쯤은 새겨볼만한 경구로 사람 배기열 보다 먼저 만난 그림 속의 화제였다.</p>
              <p>그림이 뭔지 모르는 내게 그림은 이런거 라고 얘기 하며 나를 그림 속으로 끌어들인 사람이 있다. 소위 그림이라면 명작 이라고 해서 수없이 많은 작품을 접해보았지만, 그래 그림이 이런 거구나 하며 나 스스로가 그림 속으로 들어간 것은 그의 그림을 보고 처음으로 느꼈는데, 그전까지만 해도 나는 배기열에 대해 전혀 알지 못 했다. <br />
              그도 그럴 것이 그때까지 그는 그림을 전문적으로 그려 발표하는 작가가 아니라 그림을 공부하는 학생이었기 때문이다. <br />
              그의 작품을 처음으로 접한 것이 당시 학보에 게재된 그림을 보고 나서 나는 완전히 그에게 필이 꽂혔다. 미대 2학년의 그림 속에 녹아있는 화면은 이때까지 보아온 어느 그림에서 보다 신선했으며 그의 필력은 내가 느끼기에 최고였다. 그 뒤로 수소문 끝에 그와 몇 번의 전화 통화와 스케치 여행을 통해 그의 사람다운 진솔한 향기 를 느끼며 한 세월을 함께 하고 있다.</p>
              <h4>_육지 멀미</h4>
              <p>사람이 가진 감정 가운데 가장 아름다운 것의 하나가 그리움일 것이다. 어떤 시인은 그리움이 있다는 것은 가슴 설레는 일이라고 했다. 그러나 또한 그리운 사람을 만 나지 못하는 것이나, 그리운 곳에 가지 못하는 것은 참기 어려운 고통이기도 하다. <br />
              육지이면서 멀리 떨어진 육지의 작은 섬 산에서 그리움을 안고 사는 이가 있다. 그리움의 대상은 잃어버린 과거의 무엇이 아니라, 이제까지 갖지 못했던 새로운 세계 이다. 산맥은 현실과 동경의 세계를 이어주는 통로이다. 산맥을 통하여 그가 그리는 동경의 세계로 나아갈 수 있다. 그리움의 크기를 능선에 비유하고 있다. 능선은 커 다란 그리움을 가진 자신이다. 그런데 이 능선은 얼마나 그리움이 큰지 능선이 굵고 깊게 나 있다.
              다시 능선은 미지의 세계를 동경하는 작가의 마음을 명쾌하게 드러낸다.</p>
              <p>청색이동에는 간절한 그리움이 이어진다. 그리움이 크기 때문에 기나긴 밤도 짧게 느껴지고, 그 밤에 아무 말도 기척도 없으며, 미지의 세계에서 작가를 부르는 소리, 새로운 세계를 꿈꾸는 작가는 행복하다. 어느새 능선은 붉게 변했다. 색채를 여럿 동원하지 않았음에도 화려한 느낌을 준다. 선명한 이미지 때문이다. 좋은 작품은 잔 잔하면서도 깊고 오랜 감동을 준다. <br />
              배기열은 작품에 대한 열정이 대단한 작가다. 많은 작가들이 작품의 경직성과 소재의 한계를 극복하지 못하기에 그의 작품들의 우수성은 더욱 빛나는 것이다. 지금 이 작품을 보고 있으면 마음이 환해지고, 새로운 힘이 생긴다. 어떤 작품을 보고 있노라면 분노가 치솟기도 하고, 울분을 느끼기도 하며, 때로는 허무와 무기력에 빠지 게도 한다. 또 어떤 작품은 마음이 환하게 밝아오며 편안해지고, 따뜻한 사랑이 전해져 오기도 한다. 요즘 세상에 필요한 것은, 상처를 덧나게 하는 것이 아니라 아픈 사람을 어루만져 주고 희망과 용기를 주는 작품이어야 한다고 생각한다.</p>
              </>
              <h4>_마음의 감옥</h4>
              <p>어머니께서는 자식에게 지극한 정성과 사랑을 쏟으셨는데, 자신은 그 은혜에 보답하지 못한 안타까움, 죄책감이 잘 나타나 있다. 부모님을 생각할 때에 가지는 감정은 사람마다 다를 것이다. 지극한 정성과 사랑으로 길러 주신 부모님. 그 은혜를 생각하면 감사하지만, 자신이 어떻게 보답하려고 노력했는가를 생각하면 아쉽고, 안타깝 고, 죄스러울 따름인 것이 태반의 자식들 마음이다. 어머니께서 베풀어 주시는 사랑과 은혜를 갚지 못하고 사는 자식의 심정을 아주 현실감 있게 표현하였다. <br />
              우리 가문을 위하여, 자식들을 위하여 헌신적인 삶을 살아오셨고, 작가의 장래를 기대하셨던 어머니시지만, 어머니에게 아무 것도 해 드리지 못한다. 그런 어머니를 생각하면서 흘리는 아들의 눈물이야말로 가장 값진 보답이 아닐까 한다.</p>
              <h4>_말없음의 속말들</h4>
              <p>속삭이는 듯 낮은 목소리로 말하지만 가슴 깊은 곳까지 전달되는 작품이다. 미세한 분말이 작은 틈새로 파고들 듯이, 부드러운 몸을 가진 물이 딱딱한 물체를 적시는 것처럼. 우리 마음 깊은 곳을 적시는 이 작품은 사랑이 무엇인가를 생각하게 한다. 사랑은 어떻게 하는 것인가를, 사랑의 끝은 어찌해야 하는가를 다시 생각게 한다. 그렇다. 용솟음치는 사랑은 이성으로 절제할 수 없으며, 그 사랑의 끝은 이 세상 어디에서도 찾을 수 없다. 끝없이 바치는 사랑을 통하여 우리가 행복을 느끼는 것이 라는 사랑법을 전하고 있다.</p>
              <h4>_이색적공간의 내력</h4>
              <p>시는 비수(匕首)처럼, 혹은 표창(鏢槍)처럼 작지만 사람의 급소를 공격한다. 그래서 단번에 독자에게 치명적인 감동을 주어야 한다. 그림도 마찬가지다. <br />
              이색적 공간의 내력 속에 들어가면 정말 인생은 무엇인가를 생각하게 한다. 인생은 무엇인가. 인간으로서 가장 위대하고 영광스러운 삶을 영위했다고 하는 솔로몬 왕 도 “헛되고 헛되도다.”라고 하지 않았는가. 인간의 삶이란 어차피 그런 것일지 모른다. 그렇게 허무를 향하여 달려가는 길이기에 열심히 노력하는 사람들이 더욱 돋보 이는지도 모른다. 세상을 살아가는 데에 장애가 되는 것들을 상징하는 어둠조차 긍정적으로 인식한다. 어둠이 있기에 우리는 역설적으로 세상을 살아가는 에너지를 얻을 수 있고, 만남도 이루어지는 것이다. <br />
              세상의 가치를 초월한 존재인 아무것도 아닌 당신과 아무것도 아닌 내가 만나서 지상(至上)의 가치인 아무것도 아닌 사랑을 완성하는 것이다. 그 사랑은 조청같이 화 학적으로 이루어진 사랑이 된다.</p>
              <h4>_자화상</h4>
              <p>제도와 인습을 거부하고 벌거숭이로 살고자 하는 마음을 직설적으로 표현하고 있다. 그러면 벌거숭이는 어떤 성향을 띠고 있는가. 제도와 인습에서 벗어난, 선악과 시비도 모르는 존재이다. 그리고 작가가 지향하는 이상적 삶을 실천하는 존재이기도 하다. 그렇지만 현실은 어떤가. 자신이 그리는 이상적 존재와는 거리가 먼 모습 이다. 그러한 자신을 풀과 같이 나약한 존재라고 실토한다. 그러나 그는 끝내 절망하지 않는다. 생명이 있는 한 감로(甘露)가 내릴 것이라는 믿음을 가지고 있다. 그래 서 언젠가는 생장의 축배를 올리겠다는 생각이다. 가까이 있는 청자를 상대로 하여 희망을 노래하고 있다. 나약하지만, 굴복하지 않는 강인한 의지와 미래에의 확신 이 나타난 작품이다.</p>
              <h4>_풍경</h4>
              <p>사람의 욕망은 끝이 없다. 호수가 상징하는 자연적인 것이고 순수한 것과 도시의 불빛이 상징하는 문명적이고 탈 도덕적인 것들을 두루 섭렵하였음에도 욕망은 잠들 줄 모른다. 자신을 욕망으로부터 지키고자 노력하지만, 힘없이 무너져버리는 안타까움을 토로한다. 공자도 멈출 줄 알면 욕됨이 없다고 하였다. 그러나 그 욕망이라 는 것이 그렇게 쉽게 다스려지는 것이 아니지 않은가. 여기에 인간의 한계와 고뇌가 있다. 그리하여 마침내 어찌할 도리가 없습니다. 라는 독백을 하게 되고 만다. 욕 망의 마력, 욕망 앞에서의 인간의 나약함을 잘 표현한 작품이다. <br />
              배기열은 오랜 시간 각고의 경험을 통하여 자신만의 독특한 예술세계를 구축한 작가임에 틀림없다. 빈 배에 실려 있는 작품들 역시 노력의 흔적들을 여실히 드러내 고 있기 때문이다. <br />
              작가는 하나의 영감을 떠오르게 하기 위하여 수 없는 시행착오를 한다. 그래서 그런지 플라톤(Plato)은 영감을 단순한 무의식의 산물이 아니라, 축적된 의식의 산물로 보았다. 끝없는 경험과 축적된 지식의 습득이 결국 하나의 영감을 만들어 내게 된다는 것이다.</p>
              <p>끝으로 작가 배기열이 작품 활동에 도움이 될까 하여 한 가지 덧붙인다. '인생을 살아가는데 있어서 최상의 방법은 물과 같이 살아가는 것이다.' 라고 노자의 가르침 으로 물에는 '유연'함과 '겸허'함, 그리고 '비장된 에너지'가 함께 있기 때문이다.</p>
            </blockquote>
          </div>
            
          <hr></hr>

          <h3 class="mb-5 mt-15">작가소개</h3>
          <blockquote>
            <div class="row">
              <div class="col-xxl-4 col-lg-4 col-md-12">
                <img class="img-fluid" src="./img/artist/05.jpg" alt=""/>
              </div>
              <div class="col-xxl-7 col-lg-7 col-md-12">
                <h4>배기열</h4>
                <h5 class="mt-5"><학력></h5>
                <ul class="mt-3">
                  <li>1996.서울대학교 미술대학 동양화과 학사</li>
                  <li>2006.한국예술종합학교 대학원 조형예술학과 입체전공 석사</li>
                  <li>2016.중앙대학교 첨단영상대학원 영상학과 예술공학 박사</li>
                  <li>2022.고려대학교 정책대학원 도시 및 지방행정학과 석사</li>
                </ul>
                <h5 class="mt-5">Project</h5>
                <ul class="mt-3">
                  <li>부산 서면 트루엘 센트럴 공공조형물 미술작품 설치(2020)</li>
                  <li>양양 연창삼거리 공공조형물 미술작품 설치(2019-2020)</li>
                  <li>마곡 보태닉타워 공공조형물 미술작품 설치(2019)</li>
                  <li>충남 천안 안서 금호어울림 공공조형물 미술작품 설치(2007)</li>
                  <li>파독 50주년 광부 간호사 헌정기념전 “기적을 캐내고 나라를 구하라” 총감독(2012-2013)</li>
                  <li>4대강 사업 강천보 문화관 ,물문화관 예술감독(2011-2012) 여수엑스포 한국관 전시총괄책임자(2010-2012)</li>
                  <li>군산 근대문화역사밸트화사업 전시총괄책임자(2010-2011)</li>
                </ul>
                <h5 class="mt-5"><개인전></h5>
                <ul class="mt-3">
                  <li>2011 7회 개인전 ‘Allegory Landscape' (한빛미디어갤러리, 서울)</li>
                  <li>2010 6회 개인전 ‘Allegory Landscape' (늘푸른 정원, 경기)</li>
                  <li>2009 5회 개인전 ‘Allegory Landscape' (신상 갤러리, 서울)</li>
                </ul>
              </div>
            </div>
          </blockquote>
          `
      }
    }
  },

  'ac0006': {
    'info': {
      auction_id: 'ac0006',
      status: "06",
      artwork_name: '알레고리 풍경_아이러브유 (Allegory-Landscape_I-Love-You)',
      artist_name: '배기열',
      auction_date: '2023-01-25',
      artwork: {
        size: '90 x 72cm',
        images: [
          './img/artworks/06.jpg'
        ],
        description: `
          <h3 class="mt-10">작품 설명</h3>
          <div class="mb-15">
            <blockquote>
              <h4>有德者 必有言 有言者 不必有德 仁者 必有勇 勇者 不必有仁</h4>
              <p>德(덕)이 있는 사람은 반드시 좋은 말을 남긴다. 하지만 좋은 말을 남겼다고 반드시 德(덕)이 있는 사람은 아니다. <br />
              仁(인)한 사람은 반드시 용기가 있다. 하지만 용기가 있다고 반드시 仁(인)한 사람은 아니다.란 의미로 한번쯤은 새겨볼만한 경구로 사람 배기열 보다 먼저 만난 그림 속의 화제였다.</p>
              <p>그림이 뭔지 모르는 내게 그림은 이런거 라고 얘기 하며 나를 그림 속으로 끌어들인 사람이 있다. 소위 그림이라면 명작 이라고 해서 수없이 많은 작품을 접해보았지만, 그래 그림이 이런 거구나 하며 나 스스로가 그림 속으로 들어간 것은 그의 그림을 보고 처음으로 느꼈는데, 그전까지만 해도 나는 배기열에 대해 전혀 알지 못 했다. <br />
              그도 그럴 것이 그때까지 그는 그림을 전문적으로 그려 발표하는 작가가 아니라 그림을 공부하는 학생이었기 때문이다. <br />
              그의 작품을 처음으로 접한 것이 당시 학보에 게재된 그림을 보고 나서 나는 완전히 그에게 필이 꽂혔다. 미대 2학년의 그림 속에 녹아있는 화면은 이때까지 보아온 어느 그림에서 보다 신선했으며 그의 필력은 내가 느끼기에 최고였다. 그 뒤로 수소문 끝에 그와 몇 번의 전화 통화와 스케치 여행을 통해 그의 사람다운 진솔한 향기 를 느끼며 한 세월을 함께 하고 있다.</p>
              <h4>_육지 멀미</h4>
              <p>사람이 가진 감정 가운데 가장 아름다운 것의 하나가 그리움일 것이다. 어떤 시인은 그리움이 있다는 것은 가슴 설레는 일이라고 했다. 그러나 또한 그리운 사람을 만 나지 못하는 것이나, 그리운 곳에 가지 못하는 것은 참기 어려운 고통이기도 하다. <br />
              육지이면서 멀리 떨어진 육지의 작은 섬 산에서 그리움을 안고 사는 이가 있다. 그리움의 대상은 잃어버린 과거의 무엇이 아니라, 이제까지 갖지 못했던 새로운 세계 이다. 산맥은 현실과 동경의 세계를 이어주는 통로이다. 산맥을 통하여 그가 그리는 동경의 세계로 나아갈 수 있다. 그리움의 크기를 능선에 비유하고 있다. 능선은 커 다란 그리움을 가진 자신이다. 그런데 이 능선은 얼마나 그리움이 큰지 능선이 굵고 깊게 나 있다.
              다시 능선은 미지의 세계를 동경하는 작가의 마음을 명쾌하게 드러낸다.</p>
              <p>청색이동에는 간절한 그리움이 이어진다. 그리움이 크기 때문에 기나긴 밤도 짧게 느껴지고, 그 밤에 아무 말도 기척도 없으며, 미지의 세계에서 작가를 부르는 소리, 새로운 세계를 꿈꾸는 작가는 행복하다. 어느새 능선은 붉게 변했다. 색채를 여럿 동원하지 않았음에도 화려한 느낌을 준다. 선명한 이미지 때문이다. 좋은 작품은 잔 잔하면서도 깊고 오랜 감동을 준다. <br />
              배기열은 작품에 대한 열정이 대단한 작가다. 많은 작가들이 작품의 경직성과 소재의 한계를 극복하지 못하기에 그의 작품들의 우수성은 더욱 빛나는 것이다. 지금 이 작품을 보고 있으면 마음이 환해지고, 새로운 힘이 생긴다. 어떤 작품을 보고 있노라면 분노가 치솟기도 하고, 울분을 느끼기도 하며, 때로는 허무와 무기력에 빠지 게도 한다. 또 어떤 작품은 마음이 환하게 밝아오며 편안해지고, 따뜻한 사랑이 전해져 오기도 한다. 요즘 세상에 필요한 것은, 상처를 덧나게 하는 것이 아니라 아픈 사람을 어루만져 주고 희망과 용기를 주는 작품이어야 한다고 생각한다.</p>
              </>
              <h4>_마음의 감옥</h4>
              <p>어머니께서는 자식에게 지극한 정성과 사랑을 쏟으셨는데, 자신은 그 은혜에 보답하지 못한 안타까움, 죄책감이 잘 나타나 있다. 부모님을 생각할 때에 가지는 감정은 사람마다 다를 것이다. 지극한 정성과 사랑으로 길러 주신 부모님. 그 은혜를 생각하면 감사하지만, 자신이 어떻게 보답하려고 노력했는가를 생각하면 아쉽고, 안타깝 고, 죄스러울 따름인 것이 태반의 자식들 마음이다. 어머니께서 베풀어 주시는 사랑과 은혜를 갚지 못하고 사는 자식의 심정을 아주 현실감 있게 표현하였다. <br />
              우리 가문을 위하여, 자식들을 위하여 헌신적인 삶을 살아오셨고, 작가의 장래를 기대하셨던 어머니시지만, 어머니에게 아무 것도 해 드리지 못한다. 그런 어머니를 생각하면서 흘리는 아들의 눈물이야말로 가장 값진 보답이 아닐까 한다.</p>
              <h4>_말없음의 속말들</h4>
              <p>속삭이는 듯 낮은 목소리로 말하지만 가슴 깊은 곳까지 전달되는 작품이다. 미세한 분말이 작은 틈새로 파고들 듯이, 부드러운 몸을 가진 물이 딱딱한 물체를 적시는 것처럼. 우리 마음 깊은 곳을 적시는 이 작품은 사랑이 무엇인가를 생각하게 한다. 사랑은 어떻게 하는 것인가를, 사랑의 끝은 어찌해야 하는가를 다시 생각게 한다. 그렇다. 용솟음치는 사랑은 이성으로 절제할 수 없으며, 그 사랑의 끝은 이 세상 어디에서도 찾을 수 없다. 끝없이 바치는 사랑을 통하여 우리가 행복을 느끼는 것이 라는 사랑법을 전하고 있다.</p>
              <h4>_이색적공간의 내력</h4>
              <p>시는 비수(匕首)처럼, 혹은 표창(鏢槍)처럼 작지만 사람의 급소를 공격한다. 그래서 단번에 독자에게 치명적인 감동을 주어야 한다. 그림도 마찬가지다. <br />
              이색적 공간의 내력 속에 들어가면 정말 인생은 무엇인가를 생각하게 한다. 인생은 무엇인가. 인간으로서 가장 위대하고 영광스러운 삶을 영위했다고 하는 솔로몬 왕 도 “헛되고 헛되도다.”라고 하지 않았는가. 인간의 삶이란 어차피 그런 것일지 모른다. 그렇게 허무를 향하여 달려가는 길이기에 열심히 노력하는 사람들이 더욱 돋보 이는지도 모른다. 세상을 살아가는 데에 장애가 되는 것들을 상징하는 어둠조차 긍정적으로 인식한다. 어둠이 있기에 우리는 역설적으로 세상을 살아가는 에너지를 얻을 수 있고, 만남도 이루어지는 것이다. <br />
              세상의 가치를 초월한 존재인 아무것도 아닌 당신과 아무것도 아닌 내가 만나서 지상(至上)의 가치인 아무것도 아닌 사랑을 완성하는 것이다. 그 사랑은 조청같이 화 학적으로 이루어진 사랑이 된다.</p>
              <h4>_자화상</h4>
              <p>제도와 인습을 거부하고 벌거숭이로 살고자 하는 마음을 직설적으로 표현하고 있다. 그러면 벌거숭이는 어떤 성향을 띠고 있는가. 제도와 인습에서 벗어난, 선악과 시비도 모르는 존재이다. 그리고 작가가 지향하는 이상적 삶을 실천하는 존재이기도 하다. 그렇지만 현실은 어떤가. 자신이 그리는 이상적 존재와는 거리가 먼 모습 이다. 그러한 자신을 풀과 같이 나약한 존재라고 실토한다. 그러나 그는 끝내 절망하지 않는다. 생명이 있는 한 감로(甘露)가 내릴 것이라는 믿음을 가지고 있다. 그래 서 언젠가는 생장의 축배를 올리겠다는 생각이다. 가까이 있는 청자를 상대로 하여 희망을 노래하고 있다. 나약하지만, 굴복하지 않는 강인한 의지와 미래에의 확신 이 나타난 작품이다.</p>
              <h4>_풍경</h4>
              <p>사람의 욕망은 끝이 없다. 호수가 상징하는 자연적인 것이고 순수한 것과 도시의 불빛이 상징하는 문명적이고 탈 도덕적인 것들을 두루 섭렵하였음에도 욕망은 잠들 줄 모른다. 자신을 욕망으로부터 지키고자 노력하지만, 힘없이 무너져버리는 안타까움을 토로한다. 공자도 멈출 줄 알면 욕됨이 없다고 하였다. 그러나 그 욕망이라 는 것이 그렇게 쉽게 다스려지는 것이 아니지 않은가. 여기에 인간의 한계와 고뇌가 있다. 그리하여 마침내 어찌할 도리가 없습니다. 라는 독백을 하게 되고 만다. 욕 망의 마력, 욕망 앞에서의 인간의 나약함을 잘 표현한 작품이다. <br />
              배기열은 오랜 시간 각고의 경험을 통하여 자신만의 독특한 예술세계를 구축한 작가임에 틀림없다. 빈 배에 실려 있는 작품들 역시 노력의 흔적들을 여실히 드러내 고 있기 때문이다. <br />
              작가는 하나의 영감을 떠오르게 하기 위하여 수 없는 시행착오를 한다. 그래서 그런지 플라톤(Plato)은 영감을 단순한 무의식의 산물이 아니라, 축적된 의식의 산물로 보았다. 끝없는 경험과 축적된 지식의 습득이 결국 하나의 영감을 만들어 내게 된다는 것이다.</p>
              <p>끝으로 작가 배기열이 작품 활동에 도움이 될까 하여 한 가지 덧붙인다. '인생을 살아가는데 있어서 최상의 방법은 물과 같이 살아가는 것이다.' 라고 노자의 가르침 으로 물에는 '유연'함과 '겸허'함, 그리고 '비장된 에너지'가 함께 있기 때문이다.</p>
            </blockquote>
          </div>
            
          <hr></hr>

          <h3 class="mb-5 mt-15">작가소개</h3>
          <blockquote>
            <div class="row">
              <div class="col-xxl-4 col-lg-4 col-md-12">
                <img class="img-fluid" src="./img/artist/06.jpg" alt=""/>
              </div>
              <div class="col-xxl-7 col-lg-7 col-md-12">
                <h4>배기열</h4>
                <h5 class="mt-5"><학력></h5>
                <ul class="mt-3">
                  <li>1996.서울대학교 미술대학 동양화과 학사</li>
                  <li>2006.한국예술종합학교 대학원 조형예술학과 입체전공 석사</li>
                  <li>2016.중앙대학교 첨단영상대학원 영상학과 예술공학 박사</li>
                  <li>2022.고려대학교 정책대학원 도시 및 지방행정학과 석사</li>
                </ul>
                <h5 class="mt-5">Project</h5>
                <ul class="mt-3">
                  <li>부산 서면 트루엘 센트럴 공공조형물 미술작품 설치(2020)</li>
                  <li>양양 연창삼거리 공공조형물 미술작품 설치(2019-2020)</li>
                  <li>마곡 보태닉타워 공공조형물 미술작품 설치(2019)</li>
                  <li>충남 천안 안서 금호어울림 공공조형물 미술작품 설치(2007)</li>
                  <li>파독 50주년 광부 간호사 헌정기념전 “기적을 캐내고 나라를 구하라” 총감독(2012-2013)</li>
                  <li>4대강 사업 강천보 문화관 ,물문화관 예술감독(2011-2012) 여수엑스포 한국관 전시총괄책임자(2010-2012)</li>
                  <li>군산 근대문화역사밸트화사업 전시총괄책임자(2010-2011)</li>
                </ul>
                <h5 class="mt-5"><개인전></h5>
                <ul class="mt-3">
                  <li>2011 7회 개인전 ‘Allegory Landscape' (한빛미디어갤러리, 서울)</li>
                  <li>2010 6회 개인전 ‘Allegory Landscape' (늘푸른 정원, 경기)</li>
                  <li>2009 5회 개인전 ‘Allegory Landscape' (신상 갤러리, 서울)</li>
                </ul>
              </div>
            </div>
          </blockquote>
          `
      }
    }
  },

  'ac0007': {
    'info': {
      auction_id: 'ac0007',
      status: "07",
      artwork_name: '알레고리 풍경 썬 &문 (Allegory landscape sun & moon)',
      artist_name: '배기열',
      auction_date: '2023-01-25',
      artwork: {
        size: '61 x 72cm',
        images: [
          './img/artworks/07.jpg'
        ],
        description: `
          <h3 class="mt-10">작품 설명</h3>
          <div class="mb-15">
            <blockquote>
              <h4>有德者 必有言 有言者 不必有德 仁者 必有勇 勇者 不必有仁</h4>
              <p>德(덕)이 있는 사람은 반드시 좋은 말을 남긴다. 하지만 좋은 말을 남겼다고 반드시 德(덕)이 있는 사람은 아니다. <br />
              仁(인)한 사람은 반드시 용기가 있다. 하지만 용기가 있다고 반드시 仁(인)한 사람은 아니다.란 의미로 한번쯤은 새겨볼만한 경구로 사람 배기열 보다 먼저 만난 그림 속의 화제였다.</p>
              <p>그림이 뭔지 모르는 내게 그림은 이런거 라고 얘기 하며 나를 그림 속으로 끌어들인 사람이 있다. 소위 그림이라면 명작 이라고 해서 수없이 많은 작품을 접해보았지만, 그래 그림이 이런 거구나 하며 나 스스로가 그림 속으로 들어간 것은 그의 그림을 보고 처음으로 느꼈는데, 그전까지만 해도 나는 배기열에 대해 전혀 알지 못 했다. <br />
              그도 그럴 것이 그때까지 그는 그림을 전문적으로 그려 발표하는 작가가 아니라 그림을 공부하는 학생이었기 때문이다. <br />
              그의 작품을 처음으로 접한 것이 당시 학보에 게재된 그림을 보고 나서 나는 완전히 그에게 필이 꽂혔다. 미대 2학년의 그림 속에 녹아있는 화면은 이때까지 보아온 어느 그림에서 보다 신선했으며 그의 필력은 내가 느끼기에 최고였다. 그 뒤로 수소문 끝에 그와 몇 번의 전화 통화와 스케치 여행을 통해 그의 사람다운 진솔한 향기 를 느끼며 한 세월을 함께 하고 있다.</p>
              <h4>_육지 멀미</h4>
              <p>사람이 가진 감정 가운데 가장 아름다운 것의 하나가 그리움일 것이다. 어떤 시인은 그리움이 있다는 것은 가슴 설레는 일이라고 했다. 그러나 또한 그리운 사람을 만 나지 못하는 것이나, 그리운 곳에 가지 못하는 것은 참기 어려운 고통이기도 하다. <br />
              육지이면서 멀리 떨어진 육지의 작은 섬 산에서 그리움을 안고 사는 이가 있다. 그리움의 대상은 잃어버린 과거의 무엇이 아니라, 이제까지 갖지 못했던 새로운 세계 이다. 산맥은 현실과 동경의 세계를 이어주는 통로이다. 산맥을 통하여 그가 그리는 동경의 세계로 나아갈 수 있다. 그리움의 크기를 능선에 비유하고 있다. 능선은 커 다란 그리움을 가진 자신이다. 그런데 이 능선은 얼마나 그리움이 큰지 능선이 굵고 깊게 나 있다.
              다시 능선은 미지의 세계를 동경하는 작가의 마음을 명쾌하게 드러낸다.</p>
              <p>청색이동에는 간절한 그리움이 이어진다. 그리움이 크기 때문에 기나긴 밤도 짧게 느껴지고, 그 밤에 아무 말도 기척도 없으며, 미지의 세계에서 작가를 부르는 소리, 새로운 세계를 꿈꾸는 작가는 행복하다. 어느새 능선은 붉게 변했다. 색채를 여럿 동원하지 않았음에도 화려한 느낌을 준다. 선명한 이미지 때문이다. 좋은 작품은 잔 잔하면서도 깊고 오랜 감동을 준다. <br />
              배기열은 작품에 대한 열정이 대단한 작가다. 많은 작가들이 작품의 경직성과 소재의 한계를 극복하지 못하기에 그의 작품들의 우수성은 더욱 빛나는 것이다. 지금 이 작품을 보고 있으면 마음이 환해지고, 새로운 힘이 생긴다. 어떤 작품을 보고 있노라면 분노가 치솟기도 하고, 울분을 느끼기도 하며, 때로는 허무와 무기력에 빠지 게도 한다. 또 어떤 작품은 마음이 환하게 밝아오며 편안해지고, 따뜻한 사랑이 전해져 오기도 한다. 요즘 세상에 필요한 것은, 상처를 덧나게 하는 것이 아니라 아픈 사람을 어루만져 주고 희망과 용기를 주는 작품이어야 한다고 생각한다.</p>
              </>
              <h4>_마음의 감옥</h4>
              <p>어머니께서는 자식에게 지극한 정성과 사랑을 쏟으셨는데, 자신은 그 은혜에 보답하지 못한 안타까움, 죄책감이 잘 나타나 있다. 부모님을 생각할 때에 가지는 감정은 사람마다 다를 것이다. 지극한 정성과 사랑으로 길러 주신 부모님. 그 은혜를 생각하면 감사하지만, 자신이 어떻게 보답하려고 노력했는가를 생각하면 아쉽고, 안타깝 고, 죄스러울 따름인 것이 태반의 자식들 마음이다. 어머니께서 베풀어 주시는 사랑과 은혜를 갚지 못하고 사는 자식의 심정을 아주 현실감 있게 표현하였다. <br />
              우리 가문을 위하여, 자식들을 위하여 헌신적인 삶을 살아오셨고, 작가의 장래를 기대하셨던 어머니시지만, 어머니에게 아무 것도 해 드리지 못한다. 그런 어머니를 생각하면서 흘리는 아들의 눈물이야말로 가장 값진 보답이 아닐까 한다.</p>
              <h4>_말없음의 속말들</h4>
              <p>속삭이는 듯 낮은 목소리로 말하지만 가슴 깊은 곳까지 전달되는 작품이다. 미세한 분말이 작은 틈새로 파고들 듯이, 부드러운 몸을 가진 물이 딱딱한 물체를 적시는 것처럼. 우리 마음 깊은 곳을 적시는 이 작품은 사랑이 무엇인가를 생각하게 한다. 사랑은 어떻게 하는 것인가를, 사랑의 끝은 어찌해야 하는가를 다시 생각게 한다. 그렇다. 용솟음치는 사랑은 이성으로 절제할 수 없으며, 그 사랑의 끝은 이 세상 어디에서도 찾을 수 없다. 끝없이 바치는 사랑을 통하여 우리가 행복을 느끼는 것이 라는 사랑법을 전하고 있다.</p>
              <h4>_이색적공간의 내력</h4>
              <p>시는 비수(匕首)처럼, 혹은 표창(鏢槍)처럼 작지만 사람의 급소를 공격한다. 그래서 단번에 독자에게 치명적인 감동을 주어야 한다. 그림도 마찬가지다. <br />
              이색적 공간의 내력 속에 들어가면 정말 인생은 무엇인가를 생각하게 한다. 인생은 무엇인가. 인간으로서 가장 위대하고 영광스러운 삶을 영위했다고 하는 솔로몬 왕 도 “헛되고 헛되도다.”라고 하지 않았는가. 인간의 삶이란 어차피 그런 것일지 모른다. 그렇게 허무를 향하여 달려가는 길이기에 열심히 노력하는 사람들이 더욱 돋보 이는지도 모른다. 세상을 살아가는 데에 장애가 되는 것들을 상징하는 어둠조차 긍정적으로 인식한다. 어둠이 있기에 우리는 역설적으로 세상을 살아가는 에너지를 얻을 수 있고, 만남도 이루어지는 것이다. <br />
              세상의 가치를 초월한 존재인 아무것도 아닌 당신과 아무것도 아닌 내가 만나서 지상(至上)의 가치인 아무것도 아닌 사랑을 완성하는 것이다. 그 사랑은 조청같이 화 학적으로 이루어진 사랑이 된다.</p>
              <h4>_자화상</h4>
              <p>제도와 인습을 거부하고 벌거숭이로 살고자 하는 마음을 직설적으로 표현하고 있다. 그러면 벌거숭이는 어떤 성향을 띠고 있는가. 제도와 인습에서 벗어난, 선악과 시비도 모르는 존재이다. 그리고 작가가 지향하는 이상적 삶을 실천하는 존재이기도 하다. 그렇지만 현실은 어떤가. 자신이 그리는 이상적 존재와는 거리가 먼 모습 이다. 그러한 자신을 풀과 같이 나약한 존재라고 실토한다. 그러나 그는 끝내 절망하지 않는다. 생명이 있는 한 감로(甘露)가 내릴 것이라는 믿음을 가지고 있다. 그래 서 언젠가는 생장의 축배를 올리겠다는 생각이다. 가까이 있는 청자를 상대로 하여 희망을 노래하고 있다. 나약하지만, 굴복하지 않는 강인한 의지와 미래에의 확신 이 나타난 작품이다.</p>
              <h4>_풍경</h4>
              <p>사람의 욕망은 끝이 없다. 호수가 상징하는 자연적인 것이고 순수한 것과 도시의 불빛이 상징하는 문명적이고 탈 도덕적인 것들을 두루 섭렵하였음에도 욕망은 잠들 줄 모른다. 자신을 욕망으로부터 지키고자 노력하지만, 힘없이 무너져버리는 안타까움을 토로한다. 공자도 멈출 줄 알면 욕됨이 없다고 하였다. 그러나 그 욕망이라 는 것이 그렇게 쉽게 다스려지는 것이 아니지 않은가. 여기에 인간의 한계와 고뇌가 있다. 그리하여 마침내 어찌할 도리가 없습니다. 라는 독백을 하게 되고 만다. 욕 망의 마력, 욕망 앞에서의 인간의 나약함을 잘 표현한 작품이다. <br />
              배기열은 오랜 시간 각고의 경험을 통하여 자신만의 독특한 예술세계를 구축한 작가임에 틀림없다. 빈 배에 실려 있는 작품들 역시 노력의 흔적들을 여실히 드러내 고 있기 때문이다. <br />
              작가는 하나의 영감을 떠오르게 하기 위하여 수 없는 시행착오를 한다. 그래서 그런지 플라톤(Plato)은 영감을 단순한 무의식의 산물이 아니라, 축적된 의식의 산물로 보았다. 끝없는 경험과 축적된 지식의 습득이 결국 하나의 영감을 만들어 내게 된다는 것이다.</p>
              <p>끝으로 작가 배기열이 작품 활동에 도움이 될까 하여 한 가지 덧붙인다. '인생을 살아가는데 있어서 최상의 방법은 물과 같이 살아가는 것이다.' 라고 노자의 가르침 으로 물에는 '유연'함과 '겸허'함, 그리고 '비장된 에너지'가 함께 있기 때문이다.</p>
            </blockquote>
          </div>
            
          <hr></hr>

          <h3 class="mb-5 mt-15">작가소개</h3>
          <blockquote>
            <div class="row">
              <div class="col-xxl-4 col-lg-4 col-md-12">
                <img class="img-fluid" src="./img/artist/07.jpg" alt=""/>
              </div>
              <div class="col-xxl-7 col-lg-7 col-md-12">
                <h4>배기열</h4>
                <h5 class="mt-5"><학력></h5>
                <ul class="mt-3">
                  <li>1996.서울대학교 미술대학 동양화과 학사</li>
                  <li>2006.한국예술종합학교 대학원 조형예술학과 입체전공 석사</li>
                  <li>2016.중앙대학교 첨단영상대학원 영상학과 예술공학 박사</li>
                  <li>2022.고려대학교 정책대학원 도시 및 지방행정학과 석사</li>
                </ul>
                <h5 class="mt-5">Project</h5>
                <ul class="mt-3">
                  <li>부산 서면 트루엘 센트럴 공공조형물 미술작품 설치(2020)</li>
                  <li>양양 연창삼거리 공공조형물 미술작품 설치(2019-2020)</li>
                  <li>마곡 보태닉타워 공공조형물 미술작품 설치(2019)</li>
                  <li>충남 천안 안서 금호어울림 공공조형물 미술작품 설치(2007)</li>
                  <li>파독 50주년 광부 간호사 헌정기념전 “기적을 캐내고 나라를 구하라” 총감독(2012-2013)</li>
                  <li>4대강 사업 강천보 문화관 ,물문화관 예술감독(2011-2012) 여수엑스포 한국관 전시총괄책임자(2010-2012)</li>
                  <li>군산 근대문화역사밸트화사업 전시총괄책임자(2010-2011)</li>
                </ul>
                <h5 class="mt-5"><개인전></h5>
                <ul class="mt-3">
                  <li>2011 7회 개인전 ‘Allegory Landscape' (한빛미디어갤러리, 서울)</li>
                  <li>2010 6회 개인전 ‘Allegory Landscape' (늘푸른 정원, 경기)</li>
                  <li>2009 5회 개인전 ‘Allegory Landscape' (신상 갤러리, 서울)</li>
                </ul>
              </div>
            </div>
          </blockquote>
          `
      }
    }
  },

  'ac0008': {
    'info': {
      auction_id: 'ac0008',
      status: "08",
      artwork_name: 'MSUC',
      artist_name: 'JOBS',
      auction_date: '2023-01-25',
      artwork: {
        size: '45x45cm',
        images: [
          './img/artworks/08.jpg'
        ],
        description: `
          <h3 class="mt-10">작품 설명</h3>
          <div class="mb-15">
            <blockquote>
              <h4>MSUC작품 설명</h4>
              <p>Mad Skull Universe Club(MSUC)는 1만개의 디지털 콜렉터블로 구성되어 있다. <br />
              본 작품은 그 중 하나의 작품을 선정하여 국내 유명 판화 프린트 스튜디오 더프린트와 함께 지클레 방식으로 판화로 제작한 작품이다. <br />
              장인이 수작업을 통해 만든 액자와 고급 수입 독일산 하네뮬러 용지를 사용하여 박물관 보관용 고품질로 제작되었다. <br />
              MSUC 콜렉터블 중 본 작품은 해학적인 표정과 술병을 이고 있는 풍류 그리고 요즘 시대의 후디가 노오란 배경색을 바탕으로 행복하게 표현된 수 작이다. <br />
              스컬은 인간 본연의 때묻지 않은 순수 상태의 본 얼굴을 의미하여 ‘항상 초심, 그리고 순 수로의 회귀’를 표현하고자 했다. <br />
              작가 JOBS는 MSUC의 파운더로서 MSUC 콜렉터블을 전체 기획하고 만들었으며 NFT를 통해 해당 작품들 전체를 운영관리하는 컴퓨터 프로그래머 출신의 아티스트 경영자이다.</p>
              </>
            </blockquote>
          </div>
            
          <hr></hr>

          <h3 class="mb-5 mt-15">작가소개</h3>
          <blockquote>
            <div class="row">
              <div class="col-xxl-4 col-lg-4 col-md-12">
                <img class="img-fluid" src="./img/artist/08.jpg" alt=""/>
              </div>
              <div class="col-xxl-7 col-lg-7 col-md-12">
                <h4>JOBS</h4>
                <h5 class="mt-5">MSUC 파운더</h5>
              </div>
            </div>
          </blockquote>
          `
      }
    }
  },

  'ac0009': {
    'info': {
      auction_id: 'ac0009',
      status: "09",
      artwork_name: 'MSUC X 린다손 콜라보 ',
      artist_name: '린다손',
      auction_date: '2023-01-25',
      artwork: {
        size: '53x45.5cm',
        images: [
          './img/artworks/09.jpg'
        ],
        description: `
          <h3 class="mt-10">작품 설명</h3>
          <div class="mb-15">
            <blockquote>
              <h4>RINDASON</h4>
              <p>내가 보내고 싶은 시간,자고 싶은 욕구,이루고 싶은 꿈보다 사랑하는 아이와 함께 동화책을 읽고 내 옷을 사러 갔다가 귀여운 아이 옷을 사고 요리를 싫어하지만 정성을 다해 요리를 하고
              가족들의 건강을 체크하며 나를 위한 시간이 부족하지만 오늘 하루 꿈꾸던 멋진 하루가 어렸을 적 내 상상과 다르지만 나의 뮤즈는 가족입니다. <br />
              그들로 인해 극한을 체험하고 세상에서 제일 사랑하는 아이의 애교가 그리워 집에 빨리 들어가고 싶을 때 그들에게 영감을 받고 알록달록 이쁜 그림들이 탄생할 때 
              내 심장에 제일 많음 감정을 불어 넣어주는 아이들의 엄마 이자 린다 손의 모습입니다.</p>
              </>
            </blockquote>
          </div>
            
          <hr></hr>

          <h3 class="mb-5 mt-15">작가소개</h3>
          <blockquote>
            <div class="row">
              <div class="col-xxl-4 col-lg-4 col-md-12">
                <img class="img-fluid" src="./img/artist/09.jpg" alt=""/>
              </div>
              <div class="col-xxl-7 col-lg-7 col-md-12">
                <h4>린다손</h4>
                <h5 class="mt-5"><전시이력></h5>
                <ul class="mt-3">
                  <li>2012.10.26 ~ 2012.10.28 서울디자인재단 강남트렌드센터에서 주최하는 EARTH&DESIGN 가로수길 전역에서 50여 업체 친환경 및 아이디어 디자인제품 150여개 중 커스텀 디자인으로 리사이클링 아트슈즈를 선보였다.</li>
                  <li>2014.12.21 STUDIO83 X BATONTOUCH EXHIBITION /pop-up store 참여작가</li>
                  <li>2015.06.07 BISTRO ALLI / one night intemational pop up art exhibition</li>
                  <li>2017.11.12 VARANDA INDUSTRIAL GALLERY 에서 custom workshop</li>
                  <li>2019.08.24 ~ 09.06 VARANDA INDUSTRIAL GALLERY 개인전</li>
                  <li>2019.09.06 ~ 09.08 현대카드 이태원 스페이스 -house of vans- 초대 작가 활동</li>
                  <li>2019.10.30 ~ 11.02 부산 BEXCO 국제 신발 전시회 – 커스텀쇼,전시, 팝업</li>
                  <li>2020.08.02 ~ 부산 센텀 신세계 반스매장 – 린다손아트웍 전시 반스 브랜드 쇼케이스 스토어 ,홍대점, 명동센세계 야외광장 , 압구정점, 하남점 멘토 작가 활동</li>
                  <li>2020.08.17 ~ 09.13 성수 에스팩토리 /atelier mews 전시, 워크숍 , 팝업</li>
                  <li>2020.11.21 세종대 캠퍼스타운 지원센터 / MAKERSVILL 에서 custom workshop. </li>
                  <li>2022.06.01 ~06.14  MUSINSA, Soldout Exhibition Seoul</li>
                  <li>2022.0618 다시입다 x MIND BRIDGE 21% Party custom workshop</li>
                </ul>
              </div>
            </div>
          </blockquote>
          `
      }
    }
  },

  'ac0010': {
    'info': {
      auction_id: 'ac0010',
      status: "10",
      artwork_name: 'MSUC COGI',
      artist_name: '박승범',
      auction_date: '2023-01-25',
      artwork: {
        size: '45.5x45.5cm',
        images: [
          './img/artworks/10.jpg'
        ],
        description: `
          <h3 class="mt-10">작품 설명</h3>
          <div class="mb-15">
            <blockquote>
              <h4>톱니바퀴</h4>
              <p>우리는 사회라는 거대한 기계 속에서 굴러가는 하나의 톱니바퀴다. <br />
              현대사회에서의 우리는 무의식적으로 사회의 관습과 규범에 의해 수동적인 존재 로 변해간다. 팔과 다리가 있는 톱니바퀴 ‘코기 (COGI)’ 는 감정이 있고 주체성을 가지고 행동하는 톱니바퀴로, 현대인들이 타성에서 벗어나 주체성을 회복하고자 하는 의미를 담고 있는 존재다.</p>              
              </>
            </blockquote>
            <blockquote>
              <p>이번 MSUC 콜라보 작품 역시 투박하고 녹슨 듯한 배경 질감과 대조되는 MSUC MADSKULL과 COGI의 색감을 통해 거대한 사회 속에서 주체성을 찾고자 하는 메 시지를 던지고자 한다.</p>
            </blockquote>
          </div>
            
          <hr></hr>

          <h3 class="mb-5 mt-15">작가소개</h3>
          <blockquote>
            <div class="row">
              <div class="col-xxl-4 col-lg-4 col-md-12">
                <img class="img-fluid" src="./img/artist/10.jpg" alt=""/>
              </div>
              <div class="col-xxl-7 col-lg-7 col-md-12">
                <h4>박승범</h4>
                <h5 class="mt-5"><활동이력></h5>
                <ul class="mt-3">
                  <li>2022 나이키 카시나 워크샵 with stonrok 아티스트</li>
                </ul>
                <h5 class="mt-5"><전시이력></h5>
                <ul class="mt-3">
                  <li>2022 무신사 솔드아웃 성수 <FEAST OF ART> 전시 및 설치</li>
                  <li>2022 AK 홍대 아이뮤지엄&젤리스 글로우존 그래피티 작업</li>
                  <li>2022 현대백화점 천호 <ART GROUND> 공동기획 및 전시</li>
                  <li>2022 MSUC ART FEST 2022 전시</li>
                </ul>
              </div>
            </div>
          </blockquote>
          `
      }
    }
  },

  'ac0011': {
    'info': {
      auction_id: 'ac0011',
      status: "11",
      artwork_name: 'MSUC X 창희력 콜라보',
      artist_name: '창희력',
      auction_date: '2023-01-25',
      artwork: {
        size: '42x59.4cm',
        images: [
          './img/artworks/11.jpg'
        ],
        description: `
          <h3 class="mt-10">작품 설명</h3>
          <div class="mb-15">
            <blockquote>
              <h4>TOMATO ADDICTION</h4>
              <p>토끼의 자아를 가지고 살아가는 악어 KORI는 정체성을 찾는 여행이야기입니다. <br />
              KORI가 가장 좋아하는 토마토를 가지고 토마토 농장, 일기, 꼭지 수집가, 토마토 수프 등 나날이 토마토에 대한 다양한 애착형태로 나타나고 있다.</p>
              </>
            </blockquote>
            <blockquote>
              <p>이번 MSUC #9344캐릭터 안에 KORI의 오브제 토마토 수프로 중독시키면 어떠한 반응이 일어나는지 KORI는 실험을 시작하였으며 관찰 1일차 모습을 연출하였다.</p>
            </blockquote>
          </div>
            
          <hr></hr>

          <h3 class="mb-5 mt-15">작가소개</h3>
          <blockquote>
            <div class="row">
              <div class="col-xxl-4 col-lg-4 col-md-12">
                <img class="img-fluid" src="./img/artist/11.jpg" alt=""/>
              </div>
              <div class="col-xxl-7 col-lg-7 col-md-12">
                <h4>창희력</h4>
                <h5 class="mt-5"><활동이력></h5>
                <ul class="mt-3">
                  <li>2022. [개인전] IDENTITY, DooSil_삼청동</li>
                  <li>2022. INCHEON ASIA ART SHOW, 송도컨벤시아_인천</li>
                  <li>2022. BWB_NPLANET 특별전_벡스코</li>
                  <li>2022. Urban Break 2022_COEX</li>
                  <li>2022. CRYPTO ART FES 2022_SPACE EDGE</li>
                  <li>2022. FLOWER POWER_우리옛돌박물관</li>
                  <li>2022. Re U Again, AK백화점_홍대</li>
                  <li>2021. 서울일러스트레이션 페어_COEX</li>
                  <li>2021. Maison de Noël_IHAM in Paris</li>
                  <li>2021. 2021 비상_명동</li>
                  <li>2021. NFT VILLA, 코트_인사동</li>
                  <li>2021. NFT VILLA, 빌라해밀톤_이태원</li>
                  <li>2021. Token Manifesto, Punto Blu Seoul_성수동</li>
                  <li>2019. 서울일러스트레이션 페어_COEX</li>
                  <li>2018. 토이페스티벌 ARTTOY_TAIPEI</li>
                  <li>2018. ARTTOY_KT&G 홍대상상마당</li>
                </ul>
              </div>
            </div>
          </blockquote>
          `
      }
    }
  },

  'ac0012': {
    'info': {
      auction_id: 'ac0012',
      status: "12",
      artwork_name: '8560 in dimension',
      artist_name: 'OWA-7HO 김원규',
      auction_date: '2023-01-25',
      artwork: {
        size: '53 X 45.5cm',
        images: [
          './img/artworks/12.jpg'
        ],
        description: `
          <h3 class="mt-10">작품 설명</h3>
          <div class="mb-15">
            <blockquote>
              <h4>'8560' in dimension</h4>
              <p>파괴적 균형을 MSUC #8560이 차원을 넘는 모습을 그려보았다.<br />
              그렇게 넘어와 우리에게 속했고, 같은 세계에서 만난다.</p>
              <p>불확실성으로 덮여, 발전을 예상할 수 없는 변화는 만나 보지 못한 곳으로 더해지고 더해진다.<br />
              확신하고 싶어 불확실성을 더한다.</p>
              <p>MSUC #8560에 불확실성을 더해, 변화를 만난다.</p>
              <p>*OWA-7HO [오와칠호]는 거리에서 포착한 시간의 흔적을 다층적 레이어로 은유적 탐구를 합니다.</p>
              <p>특히, ‘OWA-7HO 김원규 작가’는 거리에서 포착한 시간의 흔적을 <파괴적 균형>의 시선으로 탐구합니다. <br />
              장면은 끌어당기고, 매일 반복되어 쌓이면 흔적은 모습을 완성합니다.
              </p>
              </>
            </blockquote>
          </div>
            
          <hr></hr>

          <h3 class="mb-5 mt-15">작가소개</h3>
          <blockquote>
            <div class="row">
              <div class="col-xxl-4 col-lg-4 col-md-12">
                <img class="img-fluid" src="./img/artist/12.jpg" alt=""/>
              </div>
              <div class="col-xxl-7 col-lg-7 col-md-12">
                <h4> OWA-7HO 김원규 </h4>
                <h5 class="mt-5"><활동이력></h5>
                <ul class="mt-3">
                  <li>2022 MSUC ART FEST (갤러리K 서울타워점, 서울)</li>
                  <li>2022 MY SPECIAL HOLIDAY (아트갤러리NY, 서울)</li>
                  <li>2022 KOREA THAI JAPAN NFT Festival (언커먼 갤러리, 서울)</li>
                  <li>2022 DDP NFT 기획전 (DDP, 서울)</li>
                  <li>2022 [수상] WWD 코리아, 갤러리엑스 NEXT NIGHT OUT 공모전 파이널리스트</li>
                  <li>2022 NFT 신진작가 전시회 (바르도 아트센터, 서울)</li>
                  <li>2022 FLOWER POWER (우리옛돌박물관, 서울)</li>
                  <li>2022 THE FEAST OF ART (무신사 솔드아웃, 서울)</li>
                  <li>2022 [기획] Re U Again (AK&홍대, 서울) </li>
                  <li>2022 프로젝트555 (민트컬랙션, 서울)</li>
                  <li>2021 GNF (아트스페이스 블루스크린, 서울)</li>
                  <li>2021 NFT VILLA (빌라해밀톤, 서울)</li>
                  <li>2021 Token Manifesto (뿐또블루, 서울)</li>
                </ul>
              </div>
            </div>
          </blockquote>
          `
      }
    }
  },

  'ac0013': {
    'info': {
      auction_id: 'ac0013',
      status: "13",
      artwork_name: '오렌지색 열기를 환대하는 커다란 손이 느껴졌다. ',
      artist_name: 'OWA-7HO 기묘경',
      auction_date: '2023-01-25',
      artwork: {
        size: '45 X 45cm',
        images: [
          './img/artworks/13.jpg'
        ],
        description: `
          <h3 class="mt-10">작품 설명</h3>
          <div class="mb-15">
            <blockquote>
              <h4>오렌지색 열기를 환대하는 커다란 손이 느껴졌다.</h4>
              <p>자격지심을 해결하고 싶은 의지로 시작된 ‘비전의 크기를 스스로 보는 감각’에 대해 말하고 있습니다.</p>
              <p>흔들리는 결핍감은 의지를 만들고, 의지는 비전을 만드는 과정을 겪는 실제 이야기와 닮은 MSUC #9034는 변화하는 마음을 풍경으로 만드는 <거울 탐정 미적 세계>로 담아냈습니다.</p>
              <p>“오렌지색 열기를 환대하는 커다란 손이 느껴졌다.” <br />
              열망의 형태를 쌓아 올릴 수 있는, 자신이 손이 비로소 커다랗게 느껴졌다. 비전의 크기를 스스로 보는 감각이 시작되었다.</p>
              <p>한 인간이 직접 겪어내는 흔들리는 감정들이 작품의 뮤즈입니다.</p>
              <p>*OWA-7HO [오와칠호]는 거리에서 포착한 시간의 흔적을 다층적 레이어로 은유적 탐구를 합니다.</p>
              <p>특히, ‘OWA-7HO 기묘경 작가’는 거리에서 포착한 시간의 흔적을 '마음의 밀물 썰물'을 중심으로 그 순간에 머무르는 과정을 <거울 탐정 미적 세계>로, 변화하는 마음을 풍경으로 만듭니다.</p>
              </>
            </blockquote>
          </div>
            
          <hr></hr>

          <h3 class="mb-5 mt-15">작가소개</h3>
          <blockquote>
            <div class="row">
              <div class="col-xxl-4 col-lg-4 col-md-12">
                <img class="img-fluid" src="./img/artist/13.jpg" alt=""/>
              </div>
              <div class="col-xxl-7 col-lg-7 col-md-12">
                <h4>OWA-7HO 기묘경</h4>
                <h5 class="mt-5"><활동이력></h5>
                <ul class="mt-3">
                  <li>2022 MSUC ART FEST (갤러리K 서울타워점, 서울)</li>
                  <li>2022 MY SPECIAL HOLIDAY (아트갤러리NY, 서울)</li>
                  <li>2022 KOREA THAI JAPAN NFT Festival (언커먼 갤러리, 서울)</li>
                  <li>2022 DDP NFT 기획전 (DDP, 서울)</li>
                  <li>2022 [수상] WWD 코리아, 갤러리엑스 NEXT NIGHT OUT 공모전 파이널리스트</li>
                  <li>2022 NFT 신진작가 전시회 (바르도 아트센터, 서울)</li>
                  <li>2022 FLOWER POWER (우리옛돌박물관, 서울)</li>
                  <li>2022 무신사 솔드아웃 THE FEAST OF ART (무신사 솔드아웃, 서울) </li>
                  <li>2022 [기획] Re U Again (AK&홍대, 서울)</li>
                  <li>2022 프로젝트555 (민트컬랙션, 서울)</li>
                  <li>2014 재장전 - '없음' 그대로 응시하는 불안 (느낌가게 문득창고문을열다, 서울)</li>
                  <li>2013 [기획] CAVE TOWN (서울문화재단, 문래동 플라뇌르, 서울)</li>
                  <li>2012 행궁동점거예술축제 - 느와르의 역설 (행궁동 빈집, 수원)</li>
                  <li>2010 너는 웃는다 (서교 예술 실험센터, 서울)</li>
                  <li>2010 프린지 페스티벌 - 두려움 바이러스와 말한다 (요기가 갤러리, 서울)</li>
                </ul>
              </div>
            </div>
          </blockquote>
          `
      }
    }
  },

  'ac0014': {
    'info': {
      auction_id: 'ac0014',
      status: "14",
      artwork_name: 'MSUC X 순영 콜라보 ',
      artist_name: '순영',
      auction_date: '2023-01-25',
      artwork: {
        size: '40x40cm',
        images: [
          './img/artworks/14.jpg'
        ],
        description: `
          <h3 class="mt-10">작품 설명</h3>
          <div class="mb-15">
            <blockquote>
              <h4>순영</h4>
              <p>우리는 새로운 시대가 도래하면 두려움과 동시에 설레는 마음을 갖기 마련이다. 누구도 가보지 못한 항해의 길에서 두려움은 잠시 접어두고 그 끝은 무엇일까 갈망하며 여행을 떠나기도 한다. 나 또한 물성이 있는 작품 활동을 하는 작가로서 메타버스 세상과 Web3.0 NFT 크립토 아트를 어떻게 받아들여야 할까 두려움이 앞섰다.
              디지털의 원본 과 물성을 찍어 만들어진 디지털의 이미지는 과연 무엇이 원본일까 하는 문제, 작가로서의 삶과 긴 시간 겪은 고뇌 들 그러한 과정이 없이 프로그램을 이용하여 몇만개씩 쏟아져나오는 디지털의 이미지들 등 과연 어디까지 예술로 받아들여야 할까 하는 고민들을 했다. 
              나의 작업을 그러한 시대적 또한 나의 고민들을 천착하고 그것들을 토대 로 두가지 이미지를 분리하는 방식으로 시각화를 시도 하였다. 특히 고흐라는 대표적 작가적 삶을 살았던 화가의 자화상과 MSUC의 이미지를 대치시켜 이미지와 동시에 의미의 충돌을 시도 하였다.</p>
              <p>이제는 고민을 접고 앞서 말한 바와 같이 누구도 가보지 못한 그 끝을 향하여 이 세계를 나 역시 항해해보려고 한 다. 새로운 도전과 항해, 그 끝이 어떨지 모른다는 불안감은 잠시 접어두고 그래서 더욱 설레는 이 여행의 길을 저 의 작품 과 함께 하시기를 바라본다.</p>
              </>
            </blockquote>
          </div>
            
          <hr></hr>

          <h3 class="mb-5 mt-15">작가소개</h3>
          <blockquote>
            <div class="row">
              <div class="col-xxl-4 col-lg-4 col-md-12">
                <img class="img-fluid" src="./img/artist/14.jpg" alt=""/>
              </div>
              <div class="col-xxl-7 col-lg-7 col-md-12">
                <h4>순영</h4>
                <h5 class="mt-5"><학력></h5>
                <ul class="mt-3">
                  <li>한성대학교 대학원 회화과 서양화전공 졸업</li>
                  <li>한성대학교 미술학사 졸업</li>
                </ul>
                <h5 class="mt-5"><개인전></h5>
                <ul class="mt-3">
                  <li>2016 ‘순영 전’ 갤러리 이목 초대개인전, 서울 2015 ‘간극’ 아트스페이스 오 선정 작가전, 서울 </li>
                  <li>2012 ‘Life 29금’ 아트사간 YAP선정 작가전, 서울</li>
                  <li>2012 '석사 청구전' A&D 갤러리, 서울</li>
                  <li>2010 ‘New Entry전’ 스피돔 갤러리 국민체육진흥공단 전시기획 공모 선정, 경기도 </li>
                </ul>
                <h5 class="mt-5"><그룹전></h5>
                <ul class="mt-3">
                  <li>2022 ‘뱅크아트페어’ 인터콘티넨탈, 서울 예정</li>
                  <li>2022 ‘위드아트페어’ 인터콘티넨탈, 서울 예정</li>
                  <li>2022 ‘어반브레이크’ 코엑스, 서울 2022 ‘뱅크아트페어’ 인터콘티넨탈, 서울 예정 2019 ‘유니온아트페어’ S-Factory, 서울</li>
                  <li>2018 ‘유니온아트페어’ S-Factory, 서울</li>
                  <li>2018 ‘50만원전’ 경민현대미술관, 경기도</li>
                  <li>2017 ‘도시락전’ 문체부작은미술관, 강원도</li>
                  <li>2017 ‘아트 페스티벌 핼로우 문래’ 문래창작촌, 서울</li>
                  <li>2017 ‘아트니까 청춘이다전’ 하남문화재단, 경기도</li>
                  <li>2017 ‘지음전’ 갤러리 이목, , 서울</li>
                  <li>2016 ‘해변디자인페스티벌 청년작가 드로잉전’ 안목해수욕장 카페거리, 강원도 2016 ‘필동아트마켓 프로젝트’ 스페이스아트1, 서울</li>
                  <li>2016 ‘청년예술FESTA’ 시민청 시민플라자갤러리, 서울</li>
                  <li>소장 2015 ‘드로윙윈윈전’ 아트스페이스오, 서울 </li>
                  <li>2015 ‘Fantasy: 4’ 갤러리 이목, 서울</li>
                  <li>2010 ‘랜덤액세스전’ 백남준아트센터, 경기도</li>
                  <li>2010 ‘Post Capital Archive’ 기획전시 토탈미술관, 서울 2010 ‘성원국전3’ A&D Gallery, 서울</li>
                  <li>2010 ‘한경건전2’ 건국대학교 Art Space, 서울</li>
                  <li>2010 ‘한경건전1’ K Gallery, 서울</li>
                  <li>2010 ‘봄의 향연전 우체국’ E-post Gallery, 서울</li>
                  <li>2009 ‘젊은작가 기획 초대전’ Gallery M, 서울</li>
                  <li>2009 ‘모두를 위한 on/off전’ Gallery Lamer, 서울 2009 ‘작가 공모 선정 작가전’ Art Space MITE, 대구 2009 ‘class class한독 교류전’ A&D Gallery, 서울</li>
                  <li>2009 ‘Proto type 전’ A&D Gallery, 서울</li>
                  <li>2008 ‘New arrived 전’ A&D Gallery, 서울 </li>
                </ul>
              </div>
            </div>
          </blockquote>
          `
      }
    }
  },

  'ac0015': {
    'info': {
      auction_id: 'ac0015',
      status: "15",
      artwork_name: 'MSUC X 이효선 콜라보 ',
      artist_name: '이효선',
      auction_date: '2023-01-25',
      artwork: {
        size: '40x40cm',
        images: [
          './img/artworks/15.jpg'
        ],
        description: `
          <h3 class="mt-10">작품 설명</h3>
          <div class="mb-15">
            <blockquote>
              <h4>신인류의 초상, 잃어버린 일상을 향한 그리움의 기록</h4>
              <p>코로나 19의 확산, 잃어버린 2년이라고 불리는 이 시대는 원하지 않았음에도 빠르게 우리의 일상을 뒤집어 놓은 상실의 시대이다. 불가항력적인 시대의 흐름 속에서 달라진 일상을 살아가는 현대인의 변화된 초상을 기록한다. 
              상대방의 마스크 속 하관을 상상하는 행위를 동물 마스크를 쓴 인물을 통해 유머러스하게 표현한 평면 작품을 MSUC #181 pink skeleton과 콜라보하여 재창작을 하였다.</p>
              <p>달라진 일상으로 과거에 대한 그리움이 생기고 낯선 상황에 대한 불편함이 있을 수 있지만 유머와 해학을 통해 또 다른 즐거움으로 현재를 살아가고자 하는 메시지를 던진다.</p>
              </>
            </blockquote>
          </div>
            
          <hr></hr>

          <h3 class="mb-5 mt-15">작가소개</h3>
          <blockquote>
            <div class="row">
              <div class="col-xxl-4 col-lg-4 col-md-12">
                <img class="img-fluid" src="./img/artist/15.jpg" alt=""/>
              </div>
              <div class="col-xxl-7 col-lg-7 col-md-12">
                <h4>이효선</h4>
                <h5 class="mt-5"><개인전></h5>
                <ul class="mt-3">
                  <li>2022 <사랑과 불안 사이, 부랑하다>, project W299 (by gallery woong), 서울, 한국 </li>
                  <li>2022 <들어볼게 안녕.안녕.안녕./들려온다 멧비둘기 소리/내일도>, Vidi gallery, 서울, 한국</li>
                  <li>2022 <2003년 폐업, 뽀짝 노래방 재오픈>, Gallery artma, 서울, 한국</li>
                  <li>2022 <너무 밝지 않은 밤을 위하여>, H-contemporary gallery, 성남, 한국</li>
                  <li>2021 <밤이 없던 나날들> , gallery Art Space-ISAEK, 서울, 한국</li>
                  <li>2020 <푸른 인간의 초상>, 국회의사당 소통관 1층, 서울, 한국</li>
                </ul>
                <h5 class="mt-5"><단체전></h5>
                <ul class="mt-3">
                  <li>2022 <다다름의 끝에서> 2인전, Samjung gallery, 부산, 한국</li>
                  <li>2022 <IN-JOY OF ART>, 관훈 갤러리, 서울, 한국</li>
                  <li>2022 <Black & White> 기획전시, gallery Art Space-ISAEK, 서울, 한국</li>
                  <li>2022 <Playback / Play,back> 기획전시, Gallery tya 해방촌, 서울, 한국</li>
                  <li>2021 <Backing your inspiration> , 꼴라보하우스 도산, 파소갤러리, 서울, 한국 </li>
                  <li>2021 <VACANT FABRIC>, 파소갤러리, 서울, 한국</li>
                  <li>2021 <Project 창(窓)>, 푸에스토 갤러리, 서울, 한국 </li>
                </ul>
              </div>
            </div>
          </blockquote>
          `
      }
    }
  },

  'ac0016': {
    'info': {
      auction_id: 'ac0016',
      status: "16",
      artwork_name: 'Hedwig is Return_loss',
      artist_name: '김린정',
      auction_date: '2023-01-25',
      artwork: {
        size: '61×72cm',
        images: [
          './img/artworks/16.jpg'
        ],
        description: `
        <h3 class="mt-10">작품 설명</h3>
        <div class="mb-15">
          <blockquote>
            <h4>헤드윅의 귀환 – 자유</h4>
            <p>The Return of Hedwig _ freedom은 불행한 유년시절을 보내며 사람에게 배신당하고 사랑을 쫓으며 상처입은 헤드윅이 기구한 삶과 
            자신의 진정한 사랑(갈라진 반쪽)을 찾아가는 과정에서 스스로의 존재 이유를 깨닫고 결국 나를 찾는 순간을 표현한 작품이다.</p>
            <p>작가 휘 김린정은 타투이스트로 활동하며 타투가 대중들에게 좀더 친근하게 다가갈 수 있도록 일주일이면 지워지는
            스킨아트(Temporary Tattoo)를 바디에 표현하여 퍼포먼스 형식의 쇼를 기획하는 art directer이다. </p>
            </>
          </blockquote>
        </div>
            
          <hr></hr>

          <h3 class="mb-5 mt-15">작가소개</h3>
          <blockquote>
            <div class="row">
              <div class="col-xxl-4 col-lg-4 col-md-12">
                <img class="img-fluid" src="./img/artist/16.jpg" alt=""/>
              </div>
              <div class="col-xxl-7 col-lg-7 col-md-12">
                <h4>김린정</h4>
                <h5 class="mt-5">휘 스킨아트, 문신사 휘 대표</h5>
                <ul class="mt-3">
                  <li>건국대학교 디자인대학원 뷰티디자인 석사</li>
                  <li>현)경희대학교 글로벌미래교육원 미용예술계열 외래교수</li>
                  <li>사) 한국메이크업전문가 직업교류협회 교육이사</li>
                  <li>현)국회방송-법테랑 김현욱아나운서 분장담당/ 300인의 희망인터뷰 _ 국회의원 분장담당</li>
                  <li>2016~2019 국회방송 “국회25시” ”칭찬 릴레이” 분장담당</li>
                  <li>현)뷰티앤 패션 Magazine 스킨아트 칼럼리스트</li>
                  <li>2009 ~ 2022 “휘 스킨아트쇼””전시회” 44회  Director</li>
                  <li>2016 ~2022아시아 어워드 & 미페스티벌 – 뷰티 칼라쇼 아티스트 참여</li>
                  <li>현)미시즈모델 선발대회, 미즈실버코리아퀸콘테스트,시니어미인대회 – 메이크업.헤어 총괄 & 심사위원</li>
                  <li>현)타투, 반영구, 메이크업 국제 미용대회, 국제뷰티아티스트콘테스트심사위원 & 심사위원장</li>
                </ul>
                <h5 class="mt-5"><타투&분장-영화, 드라마, 뮤지컬></h5>
                <ul class="mt-3">
                  <li>영화- “리얼” 김수현,수지,이성민 “인랑” “희생자부활””메멘토리””쌈마니”결정적 한방” “유감스러운도시”</li>
                  <li>넷플릭스- “종이의집” 타투 분장 총괄 / “ 개미가 타고있어요“ 타투분장 총괄</li>
                  <li>드라마- 닥터 프리즈너 , 너희들은 포위됐다 , 닥터이방인</li>
                  <li>뮤지컬-’헤드윅’ ‘남자충동’ “왜그래“ 블랙스완” ‘바람의나라’</li>
                </ul>
                <h5 class="mt-5"><수상이력></h5>
                <ul class="mt-3">
                  <li>2022 코리아베스트브랜드어워드시상-K브랜드대상-문화예술타투부분</li>
                  <li>2022 한류힙합문화대상-K힙합한류아티스트상</li>
                  <li>2022 스페이스골드제3회K연애스타나눔봉사공헌대상-뷰티나눔봉사부분</li>
                  <li>2022 월드베스트브랜드대상-아티스트부분대상</li>
                  <li>2021 대한민국아름다운문화인대상-뷰티아티스트상</li>
                  <li>2019 아시아모델페스티벌 아시아미페스티벌-베스트뷰티아티스상</li>
                  <li>2018 GLOBAL GAIA AWARD in 2018 국제 아시아 스타 대상 - 분장</li>
                  <li>2018 글로벌컨슈머대상-퍼포먼스부분</li>
                  <li>2018 아시아리더대상-타투&스킨아트대상</li>
                  <li>2017 한국메이크업전문가직업교류협회-베스트아티스트상</li>
                  <li>2017 국제한류친선문화교류뷰티콘테스트 국제 공모전 피부아트 대상</li>
                  <li>2016 글로벌브랜드대상타투스킨아트부분 대상</li>
                  <li>2014 한중뷰티엑스포 월드뷰티아트디자인전& 스킨아트부분 대상</li>
                  <li>2013 국제미용건강대상-스킨아트부분</li>
                  <li>KASF 2011 피부미용경진대회 금상 (바디페인팅)</li>
                  <li>KBF 2009 한국미용 페스티벌 중앙배 미용경기대회 금상 (바디페인팅)</li>
                  <li>2006 국제미용가연합회 미용경진대회 반영구부분 대상</li>
                  <li>2004 국제미용가연합회 제회미용경진대회 타투부분 대상</li>
                  <li>대한민국 뷰티 디자인엑스포 콘테스트 장려상 (패션 스킨아트 창작문양)</li>
                  <li>제19회 서울국제 미용건강 올림픽 수원시 의회상 “속눈썹 대상”</li>
                  <li>대한민국 국제뷰티문화예술 기능대회 타투부분 대상</li>
                  <li>대한민국 국제뷰티문화예술 기능대회 반영구 부분대상</li>
                  <li>해외 교류작가회 미국초대 순회전 우수작가상</li>
                  <li>제5회 International Beauty Arts 괌 한국영사관 초대전“스킨아트부분 최우수상”</li>
                  <li>사)월드뷰티아트협회 메이크업관광코디- 스킨아트금상-</li>
                  <li>2012~2022 국회의원표창장-박덕흠,정우택,김영우,윤상현,김성태,김학용,원유철,김영배국회의원</li>
                </ul>
              </div>
            </div>
          </blockquote>
          `
      }
    }
  },

  'ac0017': {
    'info': {
      auction_id: 'ac0017',
      status: "17",
      artwork_name: 'Painkiller',
      artist_name: '비주',
      auction_date: '2023-01-25',
      artwork: {
        size: 'D40cm',
        images: [
          './img/artworks/17.jpg'
        ],
        description: `
          <h3 class="mt-10">작품 설명</h3>
          <div class="mb-15">
            <blockquote>
              <h4>Painkiller</h4>
              <p>SKULL #128두개골을 뚫고 번쩍이는 자수정과 도끼가 박혀 있다. 고통받는 모습이 나의 작품 속 쉬지 못하는 인간들과 어딘가 닮아 있다. 그래서 그를 선택했다. 그리고 그의 고통을 덜어 주기 위해 기능 좋은 알약을 선물하기로 한다. 알약을 물자 고통은 사라지고 주변이 뱅그르 돌아간다. 이제 그의 표정이 한결 나아 보인다. 그는 정말 괜찮아진 걸까.</p>
              </>
            </blockquote>
          </div>
            
          <hr></hr>

          <h3 class="mb-5 mt-15">작가소개</h3>
          <blockquote>
            <div class="row">
              <div class="col-xxl-4 col-lg-4 col-md-12">
                <img class="img-fluid" src="./img/artist/17.jpg" alt=""/>
              </div>
              <div class="col-xxl-7 col-lg-7 col-md-12">
                <h4>비주</h4>
                <h5 class="mt-5"><활동이력></h5>
                <ul class="mt-3">
                  <li>2021년 ㅣ 국내최초 한국 NFT 작가 그룹전 [빌라헤밀톤]</li>
                  <li>2021년 ㅣ 국내최초 한국 NFT 작가 그룹전 [인사동 코트]</li>
                  <li>2021년 ㅣ NUMOMO <The Token Manifesto> 전시 [뿐뚜블루]</li>
                  <li>2022년 ㅣ 홍대 AK몰 기획전 RE U AGAIN [홀대 AK몰]</li>
                  <li>2022년 ㅣ 평화캠페인 FLOWER POWER [우리옛돌박물관]</li>
                  <li>2022년 ㅣ 픽토리움 그룹전 HAPPY ROAD [현대시티아울렛 갤러리 NY]</li>
                  <li>2022년 ㅣ ART GROUND 전시 [현대백화점 천호점] </li>
                  <li>2022년 ㅣ SUPERRARE SPACES: THE FUTURE OF COMMUNITY </li>
                  <li>CURATION [SuperrareGallery in Soho NYC.]</li>
                  <li>2022년 ㅣ newera seoul artist exhibition [노들섬]</li>
                </ul>
              </div>
            </div>
          </blockquote>
          `
      }
    }
  }
};

export default auctionsInfo;