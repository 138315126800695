import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import AuctionUserHistory from './AuctionUserHistory';
import AuctionMyHistory from './AuctionMyHistory';
import ArtworkSlider from './ArtworkSlider';
import { numberComma, scrollGotoBottom } from '../../util/utils';
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.js';
import { getToday } from '../../util/utils';
import moment from 'moment';

const AuctionSet = ( {auctionId, auctionInfo, auctionRepository, authService} ) => {

  const historyState = useHistory().state;
  const [auction, setAuction] = useState({});
  const [bidRealTime, setBidRealTime] = useState({});
  const [myBid, setMyBid] = useState([]);
  const [userId, setUserId] = useState(historyState && historyState.id);
  const [user, setUserInfo] = useState({})
  const [amount, setAmount] = useState(0);
  const [bidingState, setBidingState] = useState(0);
  const [myInfoData, setMyInfoData ] = useState('');

  useEffect( () => {
    authService.onAuthChange(user => {
      if(user) {
        setUserId(user.uid);
        setUserInfo(user);
        authService.getMyInfoData(user, setMyInfoData);
      } 
    });

    const stopSync = auctionRepository.syncAuctionBid(auctionId, auction => {
      setAuction(auction);
      setAmount(auction.amount);
      console.log("auction.history: ", auction.history);
      if(auction.history) {
        setBidRealTime(auction.history.realtime || {});
        setMyBid(auction.history.users[userId] || {});
      } else {
        setBidRealTime({});
        setMyBid({});
      }

      auctionRepository.setCountDown();
      // 경매참여자 여부 확인
      auctionRepository.checkBidingUserState(auctionId, userId, setBidingState);

      setTimeout(()=> {scrollGotoBottom('user_bid_history')}, 500);
      setTimeout(()=> {scrollGotoBottom('my_bid_history')}, 500);
      
    });
    return () => stopSync();
  }, [auctionId]);


  useEffect( () => {
    const stopSync = auctionRepository.syncAuctionBid(auctionId, auction => {
      console.log("AuctionSet auction: ", auction);
      setAuction(auction);
      setAmount(auction?.amount);
      if(auction.history) {
        console.log("auction?.history?.users[userId]: ", auction?.history, auction?.history?.users, userId);
        setBidRealTime(auction?.history?.realtime || {});
        setMyBid(auction?.history?.users[userId] || {});
      } else {
        setBidRealTime({});
        setMyBid({});
      }
      auctionRepository.setCountDown();
      // 경매참여자 여부 확인
      auctionRepository.checkBidingUserState(auctionId, userId, setBidingState);

      setTimeout(()=> {scrollGotoBottom('user_bid_history')}, 500);
      setTimeout(()=> {scrollGotoBottom('my_bid_history')}, 500);
      
    });
    return () => stopSync();
  }, [auctionId]);

  const addMoney = (bidAmount) => {

    alert("경매가 종료 되었습니다.");
    return;

    const today = moment(getToday() + ' 00:00:00').valueOf();
    const todayBids = Object.keys(myBid).filter(date => parseInt(date) > today );

    const startBidingTime = today + 1000 * 60 * 60 * 12;
    console.log("today: ", today);

    // if(amount <= 0) {
    //   alert("잠시만 기다려주세요. 데이터 동기화 중입니다.");
    //   return;
    // }

    if(user.uid) {

      // 경매금액이 50만원 이상의 경우 입금 후 진행할 수 있도록 함
      // if(amount > 500000) {
        console.log("auction: ", auction);
        // 입찰보증금 납입자들
        const bidingUsers = auction ? auction.biding_user || {}: {};
        // 입찰보증금 납입여부
        const isRemit = bidingUsers[user.uid] ? bidingUsers[user.uid].remit : false;
        if(!bidingUsers || !isRemit) {
          const myModal = new bootstrap.Modal(document.getElementById('biding_regist_modal'));
          myModal.show();
          return;
        }
      // }

      // 5회 초과 방지      
      let isOverBid = todayBids.length > 4 ? true : false;
      if(isOverBid) {
        alert("일일 경매 참여회수 5회가 초괴되었습니다. 내일 참여해주세요.");
        return;
      }

      const sum = amount + bidAmount;
      setAmount(sum);
      const bid = {
        bid_id: Date.now(),
        user_id: userId,
        user_name: user.displayName,
        thumbnail: user.photoURL,
        amount: sum
      }
      auctionRepository.setAuctionBid(userId, auctionId, bid);
    } else {
      authService.openLoginModal();
    }
  }

  useEffect(() => {
    const stopSync = auctionRepository.syncAuctionMyBid(auctionId, userId, myBid => {
      setMyBid(myBid);
    });
    return () => stopSync();
  }, [bidRealTime]);

  // 나의 입찰 내역
  const myBiding = () => {
    if(!user.uid) {
      authService.openLoginModal();
    } else {
      setTimeout(()=> {scrollGotoBottom('my_bid_history')}, 500);
    }
  }

  const userBiding = () => {
    setTimeout(()=> {scrollGotoBottom('user_bid_history')}, 500);
  }


  return (
      <>
          <div className="col-xl-7 mb-7">
            <div className="explore-style-two">
              {auctionInfo.info.artwork.images.map(image => (
                <ArtworkSlider key={image} artwork={image} />
              ))}
              <div className="countdown btn btn-gradient btn-small" data-date={auctionInfo.info.auction_date}></div>
            </div>
          </div>

          <div className="col-xl-5 mb-6">
            <div className="details-content">
              <h2 className="main_title">{auctionInfo.info.artwork_name}</h2>
              <div className="row">
                <ul className="col-lg-10 col-md-10">
                  <li className="avatar-info-wrapper d-flex-between">
                    <div className="d-flex-center avatar-info mb-1">
                      <span className="owner">작가명</span>
                      <h5 className="title pl-1">{auctionInfo.info.artist_name}</h5>
                    </div>
                  </li>
                  <li className="avatar-info-wrapper d-flex-between">
                    <div className="d-flex-center avatar-info mb-3">
                      <span className="owner">작품정보</span>
                      <h5 className="title pl-1">{auctionInfo.info.artwork.size}</h5>
                    </div>
                  </li>
                </ul>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="biding-block">
                    <span>현재 경매가</span>
                    <h3 className="text-large text-decoration-none">{numberComma(amount)} 원</h3>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="biding-block">
                    <span>남은 경매 시간</span>
                    {/* <h3 className="countdown" data-date={auctionInfo.info.auction_date}></h3> */}
                    <h3 className="countdown">경매종료</h3>
                  </div>
                </div>
              </div>
              <div className="d-flex-between mb-5 mt-4">
                <ul className="nav custom-tabs">
                  <li onClick={() => userBiding()}>
                    <a className=" active" data-bs-toggle="tab" href="#info">현재 경매 현황</a>
                  </li>
                  <li onClick={() => myBiding()}>
                    <a data-bs-toggle="tab" href="#history" >내 입찰 내역</a>
                  </li>
                  <li>
                    <a data-bs-toggle="tab" href="#details">유의사항</a>
                  </li>
                </ul>
              </div>

              <div className="tab-content custom-tab-content">
                
                <div className="tab-pane fade show container active" id="info">
                  <div id="user_bid_history" className="custom-history">
                    {
                     bidRealTime && Object.keys(bidRealTime).map(key => (
                        <AuctionUserHistory key={key} bid={bidRealTime[key]} />
                      ))
                    }
                  </div>
                </div>

                <div className="tab-pane container fade" id="history">
                  <div id="my_bid_history" className="custom-history">
                  {
                    Object.keys(myBid).map(key => (
                      <AuctionMyHistory key={key} bid={myBid[key]}  />
                  ))}
                  </div>
                </div>

                <div className="tab-pane container fade" id="details">
                  <div className="custom-history">
                    <h6 className='mb-3'>유의사항</h6>
                    <ul className="info_caution mt-1">
                      <li>경매기간은 2022년 12월 26일부터 2023년 1월 26일까지 진행 됩니다.</li>
                      <li>경매는 매일 낮 12시 오픈 되어 밤 12시 종료됩니다.</li>
                      <li>밤 00시 부터 낮 11시 59분까지는 참여하실 수 없습니다.</li>
                      <li>일일 경매 참여는 5회만 참여 가능합니다.</li>
                    </ul>
                    <p className="fw-bold mb-6">경매 수익금은 미술 및 문화예술의 발전 등에 사용될 예정입니다.</p>

                    <h6 className="mb-3">경매 안내</h6>
                    <ul className="info_caution mt-1">
                      <li><strong>회원가입/로그인:</strong> 이메일로 회원가입 또는 페이스북/구글 계정으로 로그인 후 응찰하실 수 있습니다.</li>
                      <li><strong>온라인 경매:</strong> 경매페이지에서 참여금액 버튼을 눌러 응찰하실 수 있습니다. (일일 최대 5회)</li>
                      <li><strong>경매참여:</strong> 경매 참여 시 보증금 5만원을 입금하신 고객분들만 참여하실 수 있습니다.<br/>(무통장입금: 농협 301-0285-8113-21 (주)갤러리케이)</li>
                      <li><strong>낙찰 결과 확인 및 결제:</strong> 경매가 종료되면 낙찰 결과를 안내 드립니다. 낙찰자는 경매 후 총 구매대금을 입금하셔야 합니다. 낙찰 철회 시 수수료가 부과될 수 있으며 2순위 낙찰자에게 전달됩니다.</li>
                      <li><strong>작품의 수령 및 배송:</strong> 무료배송(갤러리K에서 운송)</li>
                    </ul>

                  </div>
                </div>
              </div>

              <div className="group-btn mt-8 d-flex justify-content-around">
                <button className="btn btn-fill-black " onClick={() => addMoney(50000)}>경매입찰 50,000원</button>
              </div>

            </div>
          </div>
        </>

  );
};

export default AuctionSet;