import React from 'react';

const CompanyIntroPage = (props) => (
<>
<section className="inner-page-banner bg-2 bg-image">
  <div className="container">
    <div className="inner text-center">
      <h1 className="title">아이돌 사생대회</h1>
      <nav className="mt-4">
        <ol className="breadcrumb justify-content-center">
          <li className="breadcrumb-item"><a href="#">Home</a></li>
          <li className="breadcrumb-item"><a href="#">아이돌 사생대회</a></li>
        </ol>
      </nav>
    </div>
  </div>
</section>

<section className="pt-120 pb-90 blog-details-wrapper">
    <div className="container">
      <div className="row">
        <div className="col">
          <div className="blog-content text-center">
            <h2 className="mb-7 mt-7">아이돌 사생대회 캠페인</h2>
            <img className="w-100" src="./img/company/company_01.png" alt="예능최초 아이돌 그림 기부 리얼리티, 최고의 아이돌들이 참여한 재능 기부프로젝트 이미지"/>
            <h3 className="mt-7">국내 최초 아이돌 아트 리얼리티</h3>
            <p><strong className="txt_deco_line">오마이걸, 프로미스나인, 큐브패밀리, DKZ</strong><br></br>4팀의 대세 아이돌과 유명작가들이 만들어가는 미술 프로젝트</p>
            <p>‘명곡 맛집’이라는 명성을 이어나가고 있는 실력파 아이돌 그룹 <strong className="txt_deco_line">오마이걸</strong><br></br>
            화려한 미모 뿐 아니라 실력파 아이돌로도 알려진 <strong className="txt_deco_line">프로미스나인</strong><br></br>
            ‘큐브 패밀리’로 불리는 <strong className="txt_deco_line">비투비 서은광 / 펜타곤 키노, 우석 / 라잇썸 나영, 유정</strong><br></br>
            새롭게 떠오르는 라이징스타 <strong className="txt_deco_line">DKZ</strong></p>
            <p>남다른 예술감각과 창의력을 가진 아이돌들의 그림 실력 발굴 프로그램<br></br>
            매주 새롭게 공개되는 작품들과 경매를 통해 기부로 이어지는 선행 릴레이</p>

            <blockquote className="company_block">
            <p className="company_txt_special">어린이 환우<br></br>수재민<br></br>유기동물센터<br></br>산골 마을 유소년 축구단</p>
            </blockquote>
            
            <p>따뜻한 손길로 만든 작품을 통해 마음을 전달하고자 합니다.<br></br>
            ‘아이돌 사생대회’는 기부라는 하나의 연결고리를 통해 만들어진 캠페인입니다.<br></br>
            그림이라는 매개체를 통해 따뜻한 마음을 모아 하나의 작품을 완성시켜 나갑니다.</p>

            <p>작품은 경매를 통해 판매될 예정입니다.<br></br>
            작품뿐만 아니라 다양한 형태의 굿즈를 통해 작품을 소장하실 수 있습니다.<br></br>
            캠페인을 통해 만들어진 작품은 갤러리K 남산타워점에서 누구나 관람하실 수 있습니다.</p>

            <p className="mb-20">누구나 함께 할 수 있는 캠페인<br></br>
            해당 캠페인으로 모인 따뜻한 마음은 전액 기부됩니다.</p>
            

            <h2 className="mb-7 pt-10">아이돌 사생대회 전시 안내</h2>
            <img className="company_info" src="./img/company/exhibition.png" alt="사생대회 전시 사진"/>
            <p className="mb-5 mt-3">캠페인을 통해 만들어진 작품은 갤러리K 남산타워점에서 전시를 관람하실 수 있습니다.</p>
            <p className="fs-5 lh-lg mb-20"><strong>전시기간 :</strong> 2022년 9월 20일 ~ 10월 20일<br></br>
            <strong>관람시간 :</strong> 11:00 ~ 19:00 <br></br>
            <strong>전시장소 :</strong> 갤러리K 남산 서울타워점 4층 </p>

            <hr></hr>

            <h2 className="mb-10 pt-10">ABOUT GALLERY K</h2>
            <div className="pt-5 mb-15">
              <img className="company_link_alleryk d-block mx-auto" src="./img/company/logo_galleryk.png" alt="갤러리K 로고"/>
            </div>
            <div className="row">
              <div className="col-12">
                <h3>아름다움을 넘어서 미술품이 행복을 주는 환경을 구축합니다</h3>
                <p>아름다운 미술품은 일상에서의 여유와 평화를 줍니다.<br></br>
                갤러리K를 통해 수준 높은 작품을 감상하시고, 아름다운 작품으로부터 행복을 느껴보세요.
                </p>
              </div>
              <div className="col-12 mt-5">
                <h3>한국 미술의 부흥과 세계화를 꿈꿉니다</h3>
                <p>갤러리K는 미술과 경제의 통합으로 한국미술시장의 대중화를 실현합니다.<br></br>
                  갤러리K는 미술품을 보다 쉽게 접 할 수 있는 플랫폼을 구축하여, 한국 미술시장의 대중화를 실현함으로써<br></br>
                  한국 미술의 부응을 넘어 K-POP과 같은 세계화를 꿈꾸고, ‘미술로 아름다운 세상’을 만들어 나가는 기업으로 성장하고 있습니다.
                </p>
              </div>
              <div className="col-12 mt-5">
                <img className="company_info" src="./img/company/company_02.jpg" alt="갤러리K 전경 및 내부 모습"/>
              </div>
            </div>

            <div>
              <h3 className="fs-0 m-0">아름다움을 넘어서 미술품이 행복을 주는 환경을 구축합니다</h3>
              <p className="fs-0 m-0">아름다운 미술품은 일상에서의 여유와 평화를 줍니다.
              갤러리K를 통해 수준 높은 작품을 감상하시고, 아름다운 작품으로부터 행복을 느껴보세요.
              </p>
              <h3 className="fs-0 m-0">한국 미술의 부흥과 세계화를 꿈꿉니다</h3>
              <p className="fs-0 m-0">갤러리K는 미술과 경제의 통합으로 한국미술시장의 대중화를 실현합니다.
                갤러리K는 미술품을 보다 쉽게 접 할 수 있는 플랫폼을 구축하여, 한국 미술시장의 대중화를 실현함으로써
                한국 미술의 부응을 넘어 K-POP과 같은 세계화를 꿈꾸고, ‘미술로 아름다운 세상’을 만들어 나가는 기업으로 성장하고 있습니다.
              </p>
            </div>

            <div className="row mb-5">
              <div className="col-12">
                <img className="company_info" src="./img/company/company_03.jpg" alt="갤러리K 작가 소개 이미지"/>
              </div>
            </div>
            <a href="https://www.artnomics.co.kr/" class="btn btn-outline btn-small mb-20"><span>갤러리K 사이트 방문하기</span></a>


            <hr></hr>
            <h2 className="mb-7 pt-10">자주하는 질문</h2>
            <div className="accordion accordion-flush mb-20" id="faq_acc">
              <div className="accordion-item">
                <h2 className="accordion-header" id="f_01">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#a_01" aria-expanded="false" aria-controls="a_01">
                    기부금은 어디에 사용하나요?
                  </button>
                </h2>
                <div id="a_01" className="accordion-collapse collapse text-start" aria-labelledby="f_01" data-bs-parent="#faq_acc">
                  <div className="accordion-body pb-10">작품을 통한 수익금은 어린이 환우, 수재민, 유기동물센터, 산골 마을 유소년 축구단 등에 기부됩니다.</div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="f_02">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#a_02" aria-expanded="false" aria-controls="a_02">
                    경매는 어떻게 참여하나요?
                  </button>
                </h2>
                <div id="a_02" className="accordion-collapse collapse text-start" aria-labelledby="f_02" data-bs-parent="#faq_acc">
                  <div className="accordion-body pb-10">경매페이지에서 로그인 후 참여하실 수 있습니다.</div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="f_03">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#a_03" aria-expanded="false" aria-controls="a_03">
                    작품을 직접 볼 수 있나요? 
                  </button>
                </h2>
                <div id="a_03" className="accordion-collapse collapse text-start" aria-labelledby="f_03" data-bs-parent="#faq_acc">
                  <div className="accordion-body pb-10">캠페인을 통해 만들어진 작품은 갤러리K 남산타워점에서 누구나 관람하실 수 있습니다.</div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="f_04">
                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#a_04" aria-expanded="false" aria-controls="a_04">
                    굿즈는 어디서 구매할 수 있나요?
                  </button>
                </h2>
                <div id="a_04" className="accordion-collapse collapse text-start" aria-labelledby="f_04" data-bs-parent="#faq_acc">
                  <div className="accordion-body pb-10">상단의 굿즈샵에서 구매하실 수 있습니다. 굿즈는 주문제작으로 발송되어 배송일이 소요될 수 있습니다.</div>
                </div>
              </div>
            </div>

          </div>
  

        </div>

      </div>
    </div>
  </section>

  {/* <section className="pb-120">
    <div className="container">
      <div className="section-title">
        <span className="subtitle">그리고 캠페인 VOD</span>
        <h2>지난 시즌 몰아보기</h2>
      </div>

      <div className="slider related-blog-activation  slick-arrow-between slick-gutter-15">

        <div className="single-blog">
          <a href="blog-details.html" className="thumb">
            <img src="./images/blog/1.jpg" alt="nft blog"/>
          </a>
          <div className="content">
            <ul className="meta">
              <li>27 April</li>
              <li><a href="#">Development</a></li>
              <li className="date"><i className="ri-time-line"></i>4 Hour Ago</li>
            </ul>
            <h4 className="title"><a href="blog-details.html">The Window Know to say beside you</a></h4>
          </div>
        </div>

        <div className="single-blog">
          <a href="blog-details.html" className="thumb">
            <img src="./images/blog/2.jpg" alt="nft blog" />
          </a>
          <div className="content">
            <ul className="meta">
              <li>27 March</li>
              <li><a href="#">Crypto</a></li>
              <li className="date"><i className="ri-time-line"></i>7 Hour Ago</li>
            </ul>
            <h4 className="title"><a href="blog-details.html">Save Thousands Of Lives
                Through This NFT</a></h4>
          </div>
        </div>

        <div className="single-blog">
          <a href="blog-details.html" className="thumb">
            <img src="./images/blog/3.jpg" alt="nft blog"/>
          </a>
          <div className="content">
            <ul className="meta">
              <li>14 Feb</li>
              <li><a href="#">Digital Art</a></li>
              <li className="date"><i className="ri-time-line"></i>4 Hour Ago</li>
            </ul>
            <h4 className="title"><a href="blog-details.html">The NFT Know digital marketing for you</a></h4>
          </div>
        </div>

        <div className="single-blog">
          <a href="blog-details.html" className="thumb">
            <img src="./images/blog/4.jpg" alt="nft blog"/>
          </a>
          <div className="content">
            <ul className="meta">
              <li>14 February</li>
              <li><a href="#">Web NFT</a></li>
              <li className="date"><i className="ri-time-line"></i>2 Hour Ago</li>
            </ul>
            <h4 className="title"><a href="blog-details.html">How to safely purchase NFTs on Banu</a></h4>
          </div>
        </div>

      </div>
    </div>
  </section> */}
  </>
      
  );

export default CompanyIntroPage;