//import { firebaseDatabase } from './firebase'; 
import { getDatabase, ref, set, onValue, off} from 'firebase/database';
import auctions from '../data/auctions_info.js';
import goods from '../data/goods.js';
import movies from '../data/movies.js';
import movies2 from '../data/movies_old.js';

import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.js';
class AuctionRepository {

  constructor(app) {
    this.db = getDatabase(app);
  }

  selectAuctions() {
    return auctions;
  }

  getAuction(id) {
    return auctions[id];
  }

  getGoods() {
    return goods;
  }

  getGoodInfo(goodId) {
    return goods[goodId];
  }

  getMovies() {
    return movies;
  }

  getMovies2() {
    return movies2;
  }

  syncAuction(auctionId, onUpdate) {
    const query = ref(this.db, `${auctionId}/`);
    onValue(query, (snapshot) => {
      let value = snapshot.val();
      // console.log("value: ", value);
      if(!value) {  // 경매정보가 없을 경우
        set(ref(this.db, `${auctionId}/info`), auctions[auctionId].info);
        value = auctions[auctionId];
      }      
      value && onUpdate(value);
    });
    return () => off(query);
  }

  syncAuctionInfo(auctionId, onUpdate) {
    const query = ref(this.db, `${auctionId}/info/`);
    onValue(query, (snapshot) => {
      let value = snapshot.val();
      if(!value) {  // 경매정보가 없을 경우
        set(ref(this.db, `${auctionId}/info/`), auctions[auctionId]);
        value = auctions[auctionId];
      }      
      value && onUpdate(value);
    });
    return () => off(query);
  }

  syncAuctionChat(auctionId, onUpdate) {
    const query = ref(this.db, `${auctionId}/chat`);
    onValue(query, (snapshot) => {
      const value = snapshot.val();
      value && onUpdate(value);
    });
    return () => off(query);
  }

  insertAuctionChat(userId, auctionId, chat) {
    // console.log("insertAuctionchat: ", userId, auctionId, chat);
    set(ref(this.db, `${auctionId}/chat/${chat.id}`), chat);
  }

  setAuctionBid(userId, auctionId, bid) {
    console.log("setAuctionBid: ", userId, auctionId, bid);
    set(ref(this.db, `${auctionId}/auction/bid/amount`), bid.amount);
    set(ref(this.db, `${auctionId}/auction/bid/history/realtime/${bid.bid_id}`), bid);
    set(ref(this.db, `${auctionId}/auction/bid/history/users/${userId}/${bid.bid_id}`), bid);
  }

  syncAuctionBid(auctionId, onUpdate) {
    const query = ref(this.db, `${auctionId}/auction/bid`);
    onValue(query, (snapshot) => {
      const value = snapshot.val();
      value && onUpdate(value);
    });
    return () => off(query);
  }

  syncAuctionMyBid(auctionId, userId, onUpdate) {
    const query = ref(this.db, `${auctionId}/auction/bid/history/users/${userId}`);
    onValue(query, (snapshot) => {
      const value = snapshot.val();
      value && onUpdate(value);
    });
    return () => off(query);
  }

  popupMovieClip(movId) {
    console.log("mov_id: ", movId);
    const popupObj = document.getElementById('popup_movie_clip');
    console.log("popupObj: ", popupObj);
    const myModal = new bootstrap.Modal(popupObj);
    const ifmObj =popupObj.querySelector('iframe');
    ifmObj.src = `https://www.youtube.com/embed/${movId}`;
    console.log("myModal: ", myModal);
    myModal.show();
  }

  setCountDown () {
    const script = document.createElement('script');
    script.src = 'js/vendor/count-down.js';
    document.body.appendChild(script);
  }

  getSelectedGoodsList(goodKeys) {
    console.log("goodKeys: ", goodKeys);
    const goods = this.getGoods();
    const selectedGoods = [];
    goodKeys.forEach(key => {
      selectedGoods.push(goods[key]);
    });
    return selectedGoods;
  }

  checkBidingUserState(auctionId, userId, onUpdate) {
    const query = ref(this.db, `${auctionId}/auction/bid/${userId}`);
    onValue(query, (snapshot) => {
      const value = snapshot.val();
      value && onUpdate(value);
    });
    return () => off(query);
  }

  registBiding(auctionId, userInfo) {
    console.log("bidingData: ", userInfo);
    set(ref(this.db, `${auctionId}/auction/bid/biding_user/${userInfo.user_id}`), userInfo);

  }

  insertBidingInfo( form ) {
    console.log("biding info: ", form);
    set(ref(this.db,`${form.auction_id}/auction/bid/biding_user/${form.user_id}`), form);
    alert("보증금 입금신청을 하셨습니다. 신청으로부터 24시간 이내에 송금해주시기 바랍니다.");
  }

  openBidingNoticeModal() {
    const popupObj = document.getElementById('biding_notice_modal');
    const modal = new bootstrap.Modal(popupObj);
    modal.show();
  }

}

export default AuctionRepository;