import { getDatabase, ref, set, onValue, off } from 'firebase/database';
import { getAuth, signInWithPopup, GoogleAuthProvider, FacebookAuthProvider, 
  EmailAuthProvider, createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';

import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.js';
class AuthService {

  constructor(app) {
    this.firebaseAuth = getAuth();
    this.db = getDatabase(app);
    this.googleProvider = new GoogleAuthProvider();
    this.facebookProvider = new FacebookAuthProvider();
    this.emailAuthProvider = new EmailAuthProvider();
  }

  login(providerName) {
    const authProvider = this.getProvider(providerName);
    return signInWithPopup(this.firebaseAuth, authProvider);
  }

  logout() {
    this.firebaseAuth.signOut();
    document.location.reload();
  }

  onAuthChange(updateUserData) {
    console.log("onAuthchange");
    this.firebaseAuth.onAuthStateChanged(user => {
      if(user) {
        if(user.displayName) {
          updateUserData(user);
        } else {
          //this.getUserData(user, updateUserData);
          debugger;
          updateUserData(user);
        }
      } 
    });
  }

  getProvider(providerName) {
    switch(providerName) {
      case 'Google': 
        return this.googleProvider;
      case 'Facebook':
        return this.facebookProvider;
      case 'Email':
        return this.emailAuthProvider;  
        default:
        throw new Error(`${providerName} : 확인되지 않은 Provider`);
    }
  }

  openLoginModal () {
    const myModal = new bootstrap.Modal(document.getElementById('login_modal'));
    myModal.show();
  }

  emailSignUp(userData) {
    createUserWithEmailAndPassword(this.firebaseAuth, userData.email, userData.password)
      .then((userCredential) => {
        const user = userCredential.user;
        user.displayName = userData.name;
        console.log("userCredential user: ", user);
        this.setUserData(user);
        this.closeLoginModalPopup();
        return user;
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log("err: ", errorCode, errorMessage);
      });
  }

  emailLogin(userData, updateUserData) {
    console.log("userData: ", userData);
    signInWithEmailAndPassword(this.firebaseAuth, userData.email, userData.password)
      .then((userCredential) => {
        const user = userCredential.user;
        console.log("user: ", user);
        this.closeLoginModalPopup();
      }).catch(err => {
        
        const isLogin = err.message.includes('auth/wrong-password') 
          && err.message.includes('auth/user-not-found')
        
        if(!isLogin) {
          alert("로그인 정보가 맞지 않습니다. 다시 확인하세요.");
        }
      });
  }

  setUserData(userData) {
    console.log("setUserData");
    const data = {uid: userData.uid, email: userData.email};
    if(userData.displayName) {
      data.displayName = userData.displayName;
    }
    if(userData.photoURL) {
      data.photoURL = userData.photoURL;
    }
    set(ref(this.db, `users/${userData.uid}/`), data);
    return data;
  }

  getUserData(userData, onUpdate) {
    console.log("getUserData: ", userData);
    const query = ref(this.db, `users/${userData.uid}/`);
    onValue(query, (snapshot) => {
      const value = snapshot.val();
      console.log("value: ", value);
      value && onUpdate(value);
      debugger;
    });
    return () => off(query);
  }

  closeLoginModalPopup () {
    const myModalEl = document.getElementById('login_modal');
    const modal = bootstrap.Modal.getInstance(myModalEl);
    modal.hide();
  }

  getMyInfoData(user, onUpdate) {
    const query = ref(this.db, `users/${user.uid}`);
    onValue(query, (snapshot) => {
      const value = snapshot.val();
      value && onUpdate(value);
    });
    return () => off(query);
  }

  updateUserInfoData(userData, phone) {
    set(ref(this.db, `users/${userData.uid}/phone`), phone);
    alert(`${phone} 번호로 저장하였습니다`);
  }

}

export default AuthService;