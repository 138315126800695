const movies = [

  {
    session: 1,
    mov_id: "zrZZFY5Edqk",
    title: "4화 클립영상5",
    thumbnail: "https://i.ytimg.com/vi/zrZZFY5Edqk/hq720.jpg",
    url: "https://youtu.be/zrZZFY5Edqk",
    date: "2022-10-11"
  },

  {
    session: 1,
    mov_id: "y9u97ckMOPY",
    title: "4화 클립영상4",
    thumbnail: "https://i.ytimg.com/vi/y9u97ckMOPY/hq720.jpg",
    url: "https://youtu.be/y9u97ckMOPY",
    date: "2022-10-11"
  },

  {
    session: 1,
    mov_id: "t0bxdgkV3tE",
    title: "4화 클립영상3",
    thumbnail: "https://i.ytimg.com/vi/t0bxdgkV3tE/hq720.jpg",
    url: "https://youtu.be/t0bxdgkV3tE",
    date: "2022-10-11"
  },

  {
    session: 1,
    mov_id: "1ATayJVDTx4",
    title: "4화 클립영상2",
    thumbnail: "https://i.ytimg.com/vi/1ATayJVDTx4/hq720.jpg",
    url: "https://youtu.be/1ATayJVDTx4",
    date: "2022-10-11"
  },

  {
    session: 1,
    mov_id: "Ej18Qx90_i8",
    title: "4화 클립영상1",
    thumbnail: "https://i.ytimg.com/vi/Ej18Qx90_i8/hq720.jpg",
    url: "https://youtu.be/Ej18Qx90_i8",
    date: "2022-10-11"
  },

  {
    session: 1,
    mov_id: "drWqhgYgLME",
    title: "4화 선공개",
    thumbnail: "https://i.ytimg.com/vi/drWqhgYgLME/hq720.jpg",
    url: "https://youtu.be/drWqhgYgLME",
    date: "2022-10-06"
  },


  {
    session: 1,
    mov_id: "sFF8adR2AG4",
    title: "3화 클립영상5",
    thumbnail: "https://i.ytimg.com/vi/sFF8adR2AG4/hq720.jpg",
    url: "https://youtu.be/sFF8adR2AG4",
    date: "2022-10-05"
  },
  {
    session: 1,
    mov_id: "cdA4OF6Ghw4",
    title: "3화 클립영상4",
    thumbnail: "https://i.ytimg.com/vi/cdA4OF6Ghw4/hq720.jpg",
    url: "https://youtu.be/cdA4OF6Ghw4",
    date: "2022-10-05"
  },
  {
    session: 1,
    mov_id: "lnRbfyITgrs",
    title: "3화 클립영상3",
    thumbnail: "https://i.ytimg.com/vi/lnRbfyITgrs/hq720.jpg",
    url: "https://youtu.be/lnRbfyITgrs",
    date: "2022-10-05"
  },
  {
    session: 1,
    mov_id: "RKOhqF-Kc8E",
    title: "3화 클립영상2",
    thumbnail: "https://i.ytimg.com/vi/RKOhqF-Kc8E/hq720.jpg",
    url: "https://youtu.be/RKOhqF-Kc8E",
    date: "2022-10-05"
  },

  {
    session: 1,
    mov_id: "H0XET6Qjidk",
    title: "3화 클립영상1",
    thumbnail: "https://i.ytimg.com/vi/H0XET6Qjidk/hq720.jpg",
    url: "https://youtu.be/H0XET6Qjidk",
    date: "2022-10-05"
  },

  {
    session: 1,
    mov_id: "LYgKtXl0oNY",
    title: "2화 선공개",
    thumbnail: "https://i.ytimg.com/vi/LYgKtXl0oNY/hq720.jpg",
    url: "https://youtu.be/LYgKtXl0oNY",
    date: "2022-09-20"
  },
  {
    session: 1,
    mov_id: "cZ9YAvbK7Yg",
    title: "1화 클립영상",
    thumbnail: "https://i.ytimg.com/vi/cZ9YAvbK7Yg/hq720.jpg",
    url: "https://youtu.be/cZ9YAvbK7Yg ",
    date: "2022-09-21"
  },
  {
    session: 1,
    mov_id: "X1mlB0YKOvc",
    title: "1화 클립영상",
    thumbnail: "https://i.ytimg.com/vi/X1mlB0YKOvc/hq720.jpg",
    url: "https://youtu.be/X1mlB0YKOvc",
    date: "2022-09-21"
  },
  {
    session: 1,
    mov_id: "VvT-ZpZ7lqs",
    title: "1화 선공개",
    thumbnail: "https://i.ytimg.com/vi/VvT-ZpZ7lqs/hq720.jpg",
    url: "https://youtu.be/VvT-ZpZ7lqs",
    date: "2022-09-15"
  },
  {
    session: 1,
    mov_id: "7r4Q9bY33PA",
    title: "[Teaser] 아이돌 사생대회",
    thumbnail: "https://i.ytimg.com/vi/7r4Q9bY33PA/hq720.jpg",
    url: "https://youtu.be/7r4Q9bY33PA",
    date: "2022-09-08"
  }
];

export default movies;