import React from "react";

const Footer = () => {
  return (
    <footer className="footer-wrapper">
      <div className="footer-inner pt-120 pb-80">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 mb-8">
              <div className="footer-widget first-block">
                <div className="mb-4">
                  <a href="/" className="logo-light">
                    <img src="./img/logo.png" alt="brand" />
                  </a>
                  <a href="/" className="logo logo-dark">
                    <img src="./img/logo.png" alt="brand" />
                  </a>
                </div>
                <p className="mb-5">예술, 경제가 되다!</p>
                <div className="social">
                  <a
                    className="icon-facebook"
                    target={"_blank"}
                    href="https://www.facebook.com/artnomic"
                  >
                    <i className="ri-facebook-line"></i>
                  </a>
                  <a
                    className="icon-instagram"
                    target={"_blank"}
                    href="https://www.instagram.com/gallerykofficial"
                  >
                    <i className="ri-instagram-line"></i>
                  </a>
                  <a
                    className="icon-youtube"
                    target={"_blank"}
                    href="https://www.youtube.com/channel/UCE2a8PcQunxp8OnphSwHF3w"
                  >
                    <i className="ri-youtube-line"></i>
                  </a>
                </div>
              </div>
            </div>

            <div className="col-lg-5 col-md-6 mb-8">
              <ul className="footer-list-widget footer_info_galleryk">
                <li>
                  <strong>회사명 :</strong> (주)갤러리케이
                </li>
                <li>
                  <strong>대표자 :</strong> 김정필
                </li>
                <li>
                  <strong>주소 :</strong> 서울특별시 동대문구 왕산로 16
                </li>
                <li>
                  <strong>전화 :</strong> 02-1644-2208
                </li>
                <li>
                  <strong>팩스 :</strong> 02-557-5466
                </li>
                <li>
                  <strong>사업자등록번호 :</strong> 359-86-00885{" "}
                  <a
                    target={"_blank"}
                    href="https://www.ftc.go.kr/bizCommPop.do?wrkr_no=3598600885"
                  >
                    [사업자정보확인]
                  </a>
                </li>
                <li>
                  <strong>통신판매업신고번호 :</strong> 2021-서울강남-01643
                </li>
                <li>
                  <strong>개인정보보호책임자 :</strong> 표영식
                  (help@galleryk.co.kr)
                </li>
              </ul>
            </div>

            <div className="col-lg-2 col-md-6 mb-8">
              <div className="footer-widget">
                <h4>협력사/주관사</h4>
                <ul className="footer-list-widget">
                  <li>
                    <a href="https://www.artnomics.co.kr/" target={"_blank"}>
                      (주)갤러리K 바로가기
                    </a>
                  </li>
                  <li>
                    <a href="http://artrental.or.kr/" target={"_blank"}>
                      (사)한국미술렌탈협회
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-2 col-md-6 mb-8">
              <div className="footer-widget">
                <h4>정책</h4>
                <ul className="footer-list-widget">
                  <li>
                    <a href="https://sprinkle-justice-3a1.notion.site/06a8c625c27349eeaa9cbf798941157c">
                      개인정보처리방침
                    </a>
                  </li>
                  <li>
                    <a href="https://sprinkle-justice-3a1.notion.site/1dc5a09674724eb0a5f52bd075be4e69">
                      이용약관
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            {/* <div className="col-lg-2 col-md-6 mb-8">
              <div className="footer-widget">
                <h4>메뉴</h4>
                <ul className="footer-list-widget">
                  <li><a href="blog.html">Home</a></li>
                  <li><a href="activity.html">아이돌1</a></li>
                  <li><a href="popular-collections-2.html">아이돌2</a></li>
                  <li><a href="signin.html">아이돌3</a></li>
                  <li><a href="signin.html">굿즈샵</a></li>
                </ul>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <div className="copyright text-center">
        <div className="container">
          <p>
            Copyright © 2022{" "}
            <a href="https://themeforest.net/user/ib-themes" target="_blank">
              GALLERY K
            </a>{" "}
            All Rights Reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
