import React, { useState } from 'react';
import { Link } from "react-router-dom";

const Auction = ( { data }) => {
  console.log("data: ", data);
  const [info, setInfo] = useState(data.info);

  return (
    <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-6 mb-lg-0 mb-10">
      <div className="explore-style-one">
        <div className="thumb main_auction_item">
        <Link to={'/auction/' + info.auction_id}><img src={info.artwork.images[0]} alt="nft live auction thumbnail" /></Link>
        </div>
        <div className="content">
          <div className="header d-flex-between pt-4 pb-3">
            <h3 className="title">{info.artwork_name}</h3>
          </div>

          {/* <div className="product-share-wrapper">
            <div className="profile-share d-flex-center">
              <a className="more-author-text" href="#" tabIndex="0">{users.length} 명이 입찰했습니다</a>
            </div>
          </div> */}
          <div className="py-4 d-flex-between">
            <span className="bid-owner">작가명 <strong>{info.artist_name}</strong></span>
            {/* <span className="biding-price d-flex-center"><i className="ri-arrow-up-line"></i>{numberComma(amount)}</span> */}
          </div>
          <div className="action-wrapper d-flex-between pt-4">
            <Link to={'/auction/' + info.auction_id}>
              <span className="history d-flex-center"><i className="ri-history-line"></i>경매현황 보기</span>
            </Link>
            <Link to={'/auction/' + info.auction_id}>
              <span className="btn btn-black btn-small"><span>경매하러 가기</span></span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Auction;