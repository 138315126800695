import React from 'react';

const MoviePopup = ({}) => {
  return (
    <div className="modal fade popup" id="popup_movie_clip" tabIndex="-1" role="dialog" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content bid-success-content">
          {/* <button type="button" className="btn-custom-closer" data-bs-dismiss="#popup_movie_clip" aria-label="Close">
            <i className="ri-close-fill"></i>
          </button> */}
            <iframe width="450" height="225" src="" title="" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>
      </div>
    </div>
  );
}

export default MoviePopup;