import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import AuthService from './service/auth_service';
import { firebaseApp } from './service/firebase';
import AuctionRepository from './service/auction_repository';
import ManageUserService from './service/manageUserService';

const authService = new AuthService(firebaseApp);
const auctionRepository = new AuctionRepository();
const manageUserService = new ManageUserService();

ReactDOM.render(
  <React.StrictMode>
    <App 
      authService={authService}
      auctionRepository={auctionRepository}
      manageUserService={manageUserService}
    />
  </React.StrictMode>,
  document.getElementById('root')
);
