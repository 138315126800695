import React, { memo, useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import style from "./common.module.css";
import MenuComp from './menuComp';
import MenuMobileComp from './menuMobileComp';


const Header = memo(({ onLogout, authService, auctionRepository}) => {

  const [user, setUserInfo] = useState({});
  const auctionData = auctionRepository.selectAuctions();

  const openMenu = () => {
    const popMobileMenu = document.querySelector(".popup-mobile-menu");
    popMobileMenu.classList.add("active");
  };

  const closeMenu = () => {
    const popMobileMenu = document.querySelector(".popup-mobile-menu");
    popMobileMenu.classList.remove("active");
  };

  const closeModalMenu = (e) => {
    const popMobileMenu = document.querySelector(".popup-mobile-menu");
    e.target === popMobileMenu && popMobileMenu.classList.remove("active");
  }

  const logout = () => {
    onLogout();
  }

  useEffect(() => {
    authService.onAuthChange(user => {
      if (user) {
        setUserInfo(user);
        // if(user.displayName)
        //   authService.getUserData(user, setUserInfo);
      } else {
        console.log("Not Login");
      }
    });
  }, [user]);


  return (
    <>
      <header className="ib-header pc_header header-default setting-option d-none d-xl-block">
        <div className="header-inner">
          <div className="header-left">
            <div className="mainmenu-wrapper">
              <nav id="sideNav" className="mainmenu-nav">
                <ul className="mainmenu">
                  <li>
                    <Link to="/">
                      <span className="active" href="#">Home</span>
                    </Link>
                  </li>
                  {Object.keys(auctionData).map(key => (
                    <MenuComp key={key} menuData={auctionData[key]}/>
                  ))}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </header>
      <div className="profile-icon">
        <ul className="header-right-inner">
          { user.uid ? 
            <li className="avatar-info">
              { user.photoURL ? <span className={style.avatar}><img src={user.photoURL} /></span> :
                  <span className={style.firstLetter}>{user.displayName}</span>
                  }
              <ul className="submenu">
                {/* <li><a href="#"><i className="ri-user-line"></i>프로필 수정</a></li>
                <li><a href="#"><i className="ri-edit-line"></i>내 입찰 현황</a></li> */}
                <li><a href="#"onClick={ () => logout() }><i className="ri-logout-box-r-line"></i>로그아웃</a></li>
              </ul>
            </li> :
            <button onClick={ () => authService.openLoginModal()} className="btn btn-gradient btn-small">
              <span>로그인</span>
            </button>
          }

          <li className="setting-option mobile-menu-bar d-block d-xl-none">
            <button className="hamberger-button" onClick={() => openMenu()}>
              <i className="ri-menu-2-fill"></i>
            </button>
          </li>
        </ul>
      </div> 
      
      <header className="mobile_header ib-header header-default header-fixed header--sticky fluid-header">
        <div className="header-inner d-flex align-items-center justify-content-between">
          <div className="logo">
            <Link to="/">
              <span className="logo"><img src="./img/logo.png" alt="brand" /> </span>
            </Link>
          </div>
          <div className="popup-mobile-menu" onClick={(e) => {closeModalMenu(e)}}>
            <div className="inner">
              <div className="header-top">

                <div className="close-menu" onClick={()=>{closeMenu()}}>
                  <button className="close-button">
                    <i className="ri-close-fill"></i>
                  </button>
                </div>
              </div>
              <nav>
                <ul className="mainmenu">
                  <li>
                    <Link to="/" onClick={() => {closeMenu()}}>
                      <span className="active">Home</span>
                    </Link>
                  </li>
                  {Object.keys(auctionData).map(key => (
                    <MenuMobileComp key={key} menuData={auctionData[key]}/>
                  ))}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </header>
    </>
  );
});

export default Header;
