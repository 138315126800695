import { useHistory } from 'react-router-dom';
import React, { useState } from 'react';

const LoginModal = ({authService}) => {

  const [signup, setSignup] = useState(false);
  const [emailLogin, setEmailLogin] = useState(false);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [text, setText] = useState('');
  const [isAlert, setIsAlert] = useState(false);

  const onLogin = event => {
    setEmailLogin(false);
    console.log("event:", event.currentTarget.dataset.provider);
    authService.login(event.currentTarget.dataset.provider)
    .then(data => {
      console.log("login: ", data);
      authService.closeLoginModalPopup();
    });
  }

  const onLoginEmail = () => {
    setEmailLogin(true);
  }

  const onSubmit = (event) => {
    event.preventDefault();
    if (signup) {
      authService.emailSignUp({name, email, password});
    } else {
      authService.emailLogin({email, password});
    }
  };

  const setError = (error) => {
    setText(error.toString());
    setIsAlert(true);
  };

  const onChange = (event) => {
    const {
      target: { name, value, checked },
    } = event;
    switch (name) {
      case 'username':
        return setUsername(value);
      case 'password':
        return setPassword(value);
      case 'name':
        return setName(value);
      case 'email':
        return setEmail(value);
      case 'signup':
        return setSignup(checked);
      default:
    }
  };

  return (

    <div className="modal fade" id="login_modal" tabIndex="-1" aria-labelledby="placeBitLaebl" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header flex-column px-8">
            <h3 className="modal-title" id="placeBitLaebl">로그인</h3>
          </div>
          <div className="modal-body px-8 py-5">
            <ul className="login-wrap">
              <li>
                <button onClick={onLogin} data-provider="Google" className="login_item">
                  <img src="./img/login/logo_google.svg" alt="구글 로고"/>
                  <span className="ml-3">Google 계정으로 로그인 하기</span>
                </button>
              </li>
              <li>
                <button onClick={onLogin} data-provider="Facebook" className="login_item">
                  <img src="./img/login/logo_facebook.svg" alt="페이스북 로고"/>
                  <span className="ml-3">Facebook 계정으로 로그인 하기</span>
                </button>
              </li>
              {/* {!emailLogin && (<li>
                <button onClick={onLoginEmail} className="login_item">
                  <span>이메일로 로그인 하기</span>
                </button>
              </li>)} */}
            </ul>

            {emailLogin && (<form className='auth-form' onSubmit={onSubmit}>
              <input
                name='email'
                type='email'
                placeholder='Email'
                value={email}
                onChange={onChange}
                className='form-input'
                required
              />
              {signup && (
                <input
                  name='name'
                  type='text'
                  placeholder='Name'
                  value={name}
                  onChange={onChange}
                  className='form-input'
                  required
                />
              )}
              <input
                name='password'
                type='password'
                placeholder='Password'
                value={password}
                className='form-input'
                onChange={onChange}
              />
              {/* {signup && (
                <input
                  name='url'
                  type='url'
                  placeholder='Profile Image URL'
                  value={url}
                  onChange={onChange}
                  className='form-input'
                />
              )} */}
              <div className='form-signup'>
                <label htmlFor='signup' className="mr-2">회원가입을 하시겠습니까?</label>
                <input
                  name='signup'
                  id='signup'
                  type='checkbox'
                  onChange={onChange}
                  checked={signup}
                />

              </div>
              <button className='form-btn auth-form-btn btn btn-gradient btn-small' type='submit'>
                {signup ? '회원가입' : '로그인'}
              </button>
            </form>)}
          </div>
        </div>
      </div>
    </div>

  );
};

export default LoginModal;