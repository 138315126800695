import React, {useState, useEffect} from 'react';
import AOS from "aos";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation, EffectFade } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

const MainTopBanner = () => {

  const [visit, setVisit] = useState(1);

  useEffect(() => {
    const cnt = visit + 1;
    setVisit(cnt);
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 1000
    })
  });
  
  return (
    <section key={visit} className="hero-banner-style hero-banner-style-3 bg-overlay bg-8 bg-image">
      <Swiper
        spaceBetween={0}
        centeredSlides={true}
        // autoplay={{
        //   delay: 5000,
        //   disableOnInteraction: false,
        // }}
        pagination={{
          clickable: true,
        }}
        loop={true}
        navigation={false}

        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide>
          <img className="bn_tit" data-aos="fade-up" src="./img/main/banner/banner1.jpg"/>
          <div className="org_info">
              <p><span>주최 : </span>한국미술렌탈협회 
              <span>후원 : </span>갤러리K, NFTNARA, MSUC Collectibles, MC Market, (주)엠스트 교육미디어그룹, 블록체인투데이 </p>
          </div>
        </SwiperSlide>
      </Swiper>
      {/* <div className="hero-banner_inner">
        <div className="container main_bn_txt">
          <div className="row">
            <div className="col-md-8 offset-md-2 text-center">
              <div className="banner-content">
                <img className="bn_tit" data-aos="fade-up" src="./img/artwork/bn_tit.png"/>
                <h1 className="mb-6 title" id="bn_title" data-aos="fade-up">꿈을 그리는 아이돌<br /><span>제 1회 K-POP 아이돌 사생대회</span></h1>
                <p data-aos="fade-up" id="bn_sub_title" data-aos-delay="100">아이돌의 숨겨진 예술성을 그림으로 표현하다. 다채로운 색감과 주제를 통해 만나보는 아이돌들의 특별한 이야기. 작품 판매를 통한 수익금은 월드비전과 유기견후원단체등에 일부 기부됩니다.</p>
                <div className="group-btn mt-8" id="bn_description" data-aos="fade-up" data-aos-delay="200">
                  <a href="#" className="btn btn-gradient"><span>자세히 보기</span></a>
                </div>
              </div>
            </div>

            <div className="col-md-6"> </div>

          </div>
        </div>
      </div> */}
    </section>
  );
};

export default MainTopBanner;